/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Modal, Spin } from 'antd';
import './styles.css'
import { useDispatch, useSelector } from 'react-redux';
import { selectorLoading } from '../../store/slices/loadingSlice';

const LoadingGlobal: React.FC = () => {
    const { show, message } = useSelector(selectorLoading);
    const toggleModal = (idx: number, target: boolean) => {
        // setIsModalOpen((p) => {
        //   p[idx] = target;
        //   return [...p];
        // });
    };
    return (
        <>
            <Modal
                centered
                open={show}
                className='bg-[#D5D5D5] opacity-50 loading-component bg-transparent'
                onOk={() => toggleModal(0, false)}
                onCancel={() => toggleModal(0, false)}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
                closeIcon={false}
            >
                <div className='loadingLayout w-full flex justify-center items-center'>
                    <Spin size="large" className='h-fit spin-loading' />;
                </div>
            </Modal>
            {/* {
                show &&
                (
                    <div className='loadingLayout w-full min-h-[100vh] flex justify-center items-center bg-[#D5D5D5] opacity-50'>
                        <Spin size="large" className='h-fit spin-loading' />;
                    </div>
                )
            } */}
        </>

    )

}

export default LoadingGlobal;