/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useRef, useState } from 'react';
import '../GameList/gameList.css'
import * as homePage_V2Api from '../../../../../api/homePage/homePage_V2Api';
import { ChooseBetSoccer, lineBettingSoccer } from '../../../../../api/betting/bettingApi';
import * as bettingApi from '../../../../../api/betting/bettingApi';
import useToast from '../../../../../hook/useToast';
import useDebounce from '../../../../../hook/useDebounce';
import SoccerTopHDPF_V2 from './SoccerTopHDPF_V2';
import SoccerTopOUF_V2 from './SoccerTopOUF_V2';
import SoccerTop1x2F_V2 from './SoccerTop1x2F_V2';
import SoccerTopHDPH_V2 from './SoccerTopHDPH_V2';
import SoccerTopOUH_V2 from './SoccerTopOUH_V2';
import SoccerTop1x2H_V2 from './SoccerTop1x2H_V2';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import SoccerTopA1x2F_V2 from './SoccerTopA1x2F_V2';
import StatusBarSoccerMoreTab from './StatusBarSoccerMoreTab';
import StatusBarSoccerCorrectScore from './StatusBarSoccerCorrectScore';
import StatusBarSoccerCorrectScoreFH from './StatusBarSoccerCorrectScore_FH';
// import StatusBarSoccerHTFT from './StatusBarSoccerHTFT';
// import StatusBarSoccerFGLG from './StatusBarSoccerFGLG';
// import SoccerTopOE_V2 from './SoccerTopOE_V2';
import io from 'socket.io-client';
import { Collapse } from 'antd';
import {
    MinusOutlined,
    PlusOutlined
} from '@ant-design/icons';
import { API_URL_SOCCER } from '../../../../../env';
import SoccerTop1x2HSideBet from './SoccerTop1x2HSideBet';
import SoccerTopOUHSideBet from './SoccerTopOUHSideBet';
import SoccerTopHDPHSideBet from './SoccerTopHDPHSideBet';
import SoccerTop1x2FSideBet from './SoccerTop1x2FSideBet';
import SoccerTopOUFSideBet from './SoccerTopOUFSideBet';
import SoccerTopHDPFSideBet from './SoccerTopHDPFSideBet';
import StatusBarSoccerTotalCorners from './StatusBarSoccerTotalCorners';

interface SoccerTopV2Props {
    idGame?: number | string
    homeTeam: string
    awayTeam: string
    homeScore?: number
    awayScore?: number
    homeScoreTotalCorner?: number
    awayScoreTotalCorner?: number
    tournament: string
    item: homePage_V2Api.InfoGameSoccer
    listChooseBet: ChooseBetSoccer[]
    listAddStrightBetsSlipSoccer?: bettingApi.InfoAddStrightSoccerTmp[]
    blockAllOdds?: boolean
    hiddenFirstHalf?: boolean
    chooseBetSoccer?: (
        country: string, order: number, id: number, tournament: string, game: string, teamChoose: string, scoreChoose: string, lineBetting: lineBettingSoccer, dateEvent: string, option?: any, live?: boolean, idGame?: number | string, homeScore?: number, awayScore?: number
    ) => void
    checkUpdateOdds?: (id: number, point: any, prices: any[]) => void
    checkUpdateStrightBetsSlip?: (id: number | string, scoreChoose: string, option: string, homeScore?: number, awayScore?: number) => void
}
const SoccerTopLiveV3: React.FC<SoccerTopV2Props> = ({
    idGame, homeTeam, awayTeam, tournament, item, listChooseBet, listAddStrightBetsSlipSoccer, homeScore, awayScore, homeScoreTotalCorner, awayScoreTotalCorner, blockAllOdds, hiddenFirstHalf,
    chooseBetSoccer, checkUpdateOdds, checkUpdateStrightBetsSlip
}) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [socket, setSocket] = useState<any>(null);
    const updateOdds = useRef<any[]>([]);
    // const [updateOdds, setUpdateOdds] = useState<any[]>([]);
    useEffect(() => {
        if (updateOdds.current) {
            listChooseBet.forEach(elementChooseBet => {
                updateOdds.current.forEach(elementUpdateOdd => {
                    if (elementChooseBet.id === elementUpdateOdd.id) {
                        checkUpdateOdds?.(elementUpdateOdd.id, elementUpdateOdd.point, elementUpdateOdd.prices)
                    }
                });
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateOdds.current])


    useEffect(() => {


        // var dataOld: any
        const socketInstance = io(API_URL_SOCCER);
        setSocket(socketInstance);

        socketInstance.on('connect', () => {
            console.log('Connected to server live');
        });
        socketInstance.emit("gameId-soccer", Number(item.id));

        socketInstance.on('message-soccer', async (data) => {
            updateOdds.current = data.payload?.data?.eventOdds?.updated
            if (data.length > 0) {
                let gameSubbet = data.filter((itemData: any) => (itemData.id === item.id))?.[0]?.odds.filter((item: any) => (item.marketType === "OddEven" || item.marketType === "FH_CorrectScore" || item.marketType === "TotalGoal" || item.marketType === "DoubleChance" || item.marketType === "CorrectScore"))

                let gameSubbetTotalCorners = data.filter((itemData: any) => (itemData.id === item.id))?.[0]?.odds.filter((item: any) => (item.marketType === "TotalCorners_FH_OverUnder" || item.marketType === "TotalCorners_FH_Handicap" || item.marketType === "TotalCorners_OverUnder" || item.marketType === "TotalCorners_Handicap"))
                let filterSubbet: any[] = []
                if (gameSubbet && gameSubbet.length) {
                    filterSubbet = Array.from(new Map(gameSubbet.map((item: any) => [item.marketType, item])).values());
                }
                // setCountNumberSubbet(Number(filterSubbet?.length))
                setCountNumberSubbet(filterSubbet?.length ? (gameSubbetTotalCorners.length ? Number(filterSubbet?.length) + 1 : filterSubbet?.length) : 0)
            }

        });
        return () => {
            if (socketInstance) {
                socketInstance.disconnect();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const navigator = useNavigate()
    // const pushLoading = useLoading();
    const pushToast = useToast();

    const chooseBetSoccerTopHDPF = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "Fulltime HDP", item.realKickoffTime, option, true, idGame, homeScore, awayScore)
    }
    const chooseBetSoccerTopOUF = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "Fulltime OU", item.realKickoffTime, option, true, idGame, homeScore, awayScore)
    }
    const chooseBetSoccerTop1x2F = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "Fulltime 1x2", item.realKickoffTime, option, true, idGame, homeScore, awayScore)
    }

    const chooseBetSoccerTopHDPH = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "First Half HDP", item.realKickoffTime, option, true, idGame, homeScore, awayScore)
    }
    const chooseBetSoccerTopOUH = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "First Half OU", item.realKickoffTime, option, true, idGame, homeScore, awayScore)
    }
    const chooseBetSoccerTop1x2H = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "First Half 1x2", item.realKickoffTime, option, true, idGame, homeScore, awayScore)
    }

    const chooseBetSoccerOEF = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "OddEven", item.realKickoffTime, option, true, idGame, homeScore, awayScore)
    }

    const chooseBetSoccerTotalGoal = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "Total Goal", item.realKickoffTime, option, true, idGame, homeScore, awayScore)
    }

    const chooseBetSoccerDoubleChance = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "Double Chance", item.realKickoffTime, option, true, idGame, homeScore, awayScore)
    }

    const chooseBetSoccerCorrectScore = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "Correct Score", item.realKickoffTime, option, true, idGame, homeScore, awayScore)
    }

    const chooseBetSoccerCorrectScoreFH = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "First Half Correct Score", item.realKickoffTime, option, true, idGame, homeScore, awayScore)
    }

    // const chooseBetSoccerHTFT = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
    //     chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "Half Time/Full Time", item.showTime, option, true)
    // }

    // const chooseBetSoccerFGLG = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
    //     chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "First Goal/Last Goal", item.showTime, option, true)
    // }


    const chooseBetSoccerTotalCornersOverUnder = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "Fulltime Total Corners OU", item.realKickoffTime, option, true, idGame, homeScoreTotalCorner, awayScoreTotalCorner)
    }

    const chooseBetSoccerTotalCornersHandicap = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "Fulltime Total Corners HDP", item.realKickoffTime, option, true, idGame, homeScoreTotalCorner, awayScoreTotalCorner)
    }


    const chooseBetTopTotalCornersFHHandicap = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "First Half Total Corners HDP", item.realKickoffTime, option, true, idGame, homeScoreTotalCorner, awayScoreTotalCorner)
    }

    const chooseBetTopTotalCornersFHOU = (order: number, id: number, teamChoose: string, scoreChoose: string, option?: any) => {
        chooseBetSoccer?.('', order, id, tournament, item.homeTeam.teamName + " / " + item.awayTeam.teamName, teamChoose, scoreChoose, "First Half Total Corners OU", item.realKickoffTime, option, true, idGame, homeScoreTotalCorner, awayScoreTotalCorner)
    }

    const [oddTopHDPF, setOddTopHDPF] = useState<any[]>([])
    const [oddTopOUF, setOddTopOUF] = useState<any[]>([])
    const [oddTop1X2, setOddTop1X2] = useState<any[]>([])
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [oddEven, setOddEven] = useState<any[]>([])

    const [oddTopHDPFH, setOddTopHDPFH] = useState<any[]>([])
    const [oddTopOUFH, setOddTopOUFH] = useState<any[]>([])
    const [oddTop1X2FH, setOddTop1X2FH] = useState<any[]>([])

    const [totalGoal, setTotalGoal] = useState<any[]>([])
    const [doubleChance, setDoubleChance] = useState<any[]>([])
    const [correctScore, setCorrectScore] = useState<any[]>([])
    const [correctScoreFH, setCorrectScoreFH] = useState<any[]>([])
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [HTFT, setHTFT] = useState<any[]>([])
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [FGLG, setFGLG] = useState<any[]>([])

    const [totalCornersOverUnder, setTotalCornersOverUnder] = useState<any[]>([])
    const [totalCornersHandicap, setTotalCornersHandicap] = useState<any[]>([])
    const [totalCornersFHOverUnder, setTotalCornersFHOverUnder] = useState<any[]>([])
    const [totalCornersFHHandicap, setTotalCornersFHHandicap] = useState<any[]>([])


    const [oddTopHDPFOld, setOddTopHDPFOld] = useState<any[]>([])
    const [oddTopOUFOld, setOddTopOUFOld] = useState<any[]>([])
    const [oddTop1X2Old, setOddTop1X2Old] = useState<any[]>([])

    const [oddTopHDPFHOld, setOddTopHDPFHOld] = useState<any[]>([])
    const [oddTopOUFHOld, setOddTopOUFHOld] = useState<any[]>([])
    const [oddTop1X2FHOld, setOddTop1X2FHOld] = useState<any[]>([])

    const [oddEvenOld, setOddEvenOld] = useState<any[]>([])
    const [totalGoalOld, setTotalGoalOld] = useState<any[]>([])
    const [doubleChanceOld, setDoubleChanceOld] = useState<any[]>([])
    const [correctScoreOld, setCorrectScoreOld] = useState<any[]>([])
    const [correctScoreFHOld, setCorrectScoreFHOld] = useState<any[]>([])

    const [totalCornersOverUnderOld, setTotalCornersOverUnderOld] = useState<any[]>([])
    const [totalCornersHandicapOld, setTotalCornersHandicapOld] = useState<any[]>([])
    const [totalCornersFHOverUnderOld, setTotalCornersFHOverUnderOld] = useState<any[]>([])
    const [totalCornersFHHandicapOld, setTotalCornersFHHandicapOld] = useState<any[]>([])


    const [countNumberSubbet, setCountNumberSubbet] = useState<number>(0)
    //Soccer
    const gOddsByTournamentId = useDebounce(async () => {
        // pushLoading(true);
        const ketQua = await homePage_V2Api.getOddsSoccerByGameId(item.id);
        if (ketQua?.success) {
            const SubbetTotalCorners = ketQua.data.filter((item) => (item.marketType === "TotalCorners_FH_OverUnder" || item.marketType === "TotalCorners_FH_Handicap" || item.marketType === "TotalCorners_OverUnder" || item.marketType === "TotalCorners_Handicap"))
            const Subbet = ketQua.data.filter((item) => (item.marketType === "OddEven" || item.marketType === "FH_CorrectScore" || item.marketType === "TotalGoal" || item.marketType === "DoubleChance" || item.marketType === "CorrectScore"))
            const filterSubbet: any[] = Array.from(new Map(Subbet.map(item => [item.marketType, item])).values());
            // setCountNumberSubbet(Number(filterSubbet.length))
            setCountNumberSubbet(SubbetTotalCorners.length ? Number(filterSubbet.length) + 1 : filterSubbet.length)

            const Handicap = ketQua.data.filter((item) => item.marketType === "Handicap")
            Handicap.forEach(subArray => {
                subArray?.prices.sort((a, b) => b.option.localeCompare(a.option));
            });
            setOddTopHDPF(Handicap);

            const OverUnder = ketQua.data.filter((item) => item.marketType === "OverUnder")
            OverUnder.forEach(subArray => {
                subArray?.prices.sort((a, b) => b.option.localeCompare(a.option));
            });
            setOddTopOUF(OverUnder);
            var _1X2: any = ketQua.data.filter((item) => item.marketType === "_1X2")

            let _1X2Filter = _1X2
            if (_1X2Filter && _1X2Filter.length > 0) {
                var option1 = _1X2?.[0]?.prices?.filter((item: any) => item.option === "1")
                var option2 = _1X2?.[0]?.prices?.filter((item: any) => item.option === "2")
                var optionX = _1X2?.[0]?.prices?.filter((item: any) => item.option === "X")
                _1X2Filter[0].prices = [option1?.[0], option2?.[0], optionX?.[0]]
            }
            setOddTop1X2(_1X2Filter);
            const OddEven = ketQua.data.filter((item) => item.marketType === "OddEven")
            setOddEven(OddEven);

            if (!hiddenFirstHalf || hiddenFirstHalf === undefined) {
                const FH_Handicap = ketQua.data.filter((item) => item.marketType === "FH_Handicap")
                FH_Handicap.forEach(subArray => {
                    subArray?.prices.sort((a, b) => b.option.localeCompare(a.option));
                });
                setOddTopHDPFH(FH_Handicap);
                const FH_OverUnder = ketQua.data.filter((item) => item.marketType === "FH_OverUnder")
                FH_OverUnder.forEach(subArray => {
                    subArray?.prices.sort((a, b) => b.option.localeCompare(a.option));
                });
                setOddTopOUFH(FH_OverUnder);
                const FH_1X2 = ketQua.data.filter((item) => item.marketType === "FH_1X2")
                // setOddTop1X2FH(FH_1X2);
                let FH_1X2Filter = FH_1X2
                if (FH_1X2Filter && FH_1X2Filter.length > 0) {
                    var optionFH1 = FH_1X2?.[0]?.prices?.filter((item: any) => item.option === "1")
                    var optionFH2 = FH_1X2?.[0]?.prices?.filter((item: any) => item.option === "2")
                    var optionFHX = FH_1X2?.[0]?.prices?.filter((item: any) => item.option === "X")
                    FH_1X2Filter[0].prices = [optionFH1?.[0], optionFH2?.[0], optionFHX?.[0]]
                }
                setOddTop1X2FH(FH_1X2Filter);

                const correctScoreFH = ketQua.data.filter((item) => item.marketType === "FH_CorrectScore")
                setCorrectScoreFH(correctScoreFH);

                const TotalCornersFHOverUnderParam = ketQua.data.filter((item) => item.marketType === "TotalCorners_FH_OverUnder")
                setTotalCornersFHOverUnder(TotalCornersFHOverUnderParam);

                const TotalCornersFHHandicapParam = ketQua.data.filter((item) => item.marketType === "TotalCorners_FH_Handicap")
                setTotalCornersFHHandicap(TotalCornersFHHandicapParam);
            } else {
                setOddTopHDPFH([])
                setOddTopOUFH([])
                setOddTop1X2FH([])
                setCorrectScoreFH([])
                setTotalCornersFHOverUnder([])
                setTotalCornersFHHandicap([])
            }
            const TotalGoal = ketQua.data.filter((item) => item.marketType === "TotalGoal")
            setTotalGoal(TotalGoal);
            const DoubleChance = ketQua.data.filter((item) => item.marketType === "DoubleChance")
            setDoubleChance(DoubleChance);
            const CorrectScore = ketQua.data.filter((item) => item.marketType === "CorrectScore")
            setCorrectScore(CorrectScore);
            const HTFTParam = ketQua.data.filter((item) => item.marketType === "HTFT")
            setHTFT(HTFTParam);
            const FGLGParam = ketQua.data.filter((item) => item.marketType === "FGLG")
            setFGLG(FGLGParam);

            const TotalCornersOverUnderParam = ketQua.data.filter((item) => item.marketType === "TotalCorners_OverUnder")
            setTotalCornersOverUnder(TotalCornersOverUnderParam);

            const TotalCornersHandicapParam = ketQua.data.filter((item) => item.marketType === "TotalCorners_Handicap")
            setTotalCornersHandicap(TotalCornersHandicapParam);


        } else {
            // setListGameSoccer([]);
            pushToast(ketQua?.message, "warn");
        }
        // pushLoading(false);
    }, 1000)

    useEffect(() => {
        if (item.id)
            gOddsByTournamentId()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setOddTopHDPFOld(oddTopHDPF)
        setOddTopHDPF(item.odds?.filter((item) => item.marketType === "Handicap") ? item.odds?.filter((item) => item.marketType === "Handicap") : [])

        setOddTopOUFOld(oddTopOUF)
        setOddTopOUF(item.odds?.filter((item) => item.marketType === "OverUnder") ? item.odds?.filter((item) => item.marketType === "OverUnder") : [])

        setOddTop1X2Old(oddTop1X2)
        setOddTop1X2(item.odds?.filter((item) => item.marketType === "_1X2") ? item.odds?.filter((item) => item.marketType === "_1X2") : [])

        if (!hiddenFirstHalf || hiddenFirstHalf === undefined) {
            setOddTopHDPFHOld(oddTopHDPFH)
            setOddTopHDPFH(item.odds?.filter((item) => item.marketType === "FH_Handicap") ? item.odds?.filter((item) => item.marketType === "FH_Handicap") : [])

            setOddTopOUFHOld(oddTopOUFH)
            setOddTopOUFH(item.odds?.filter((item) => item.marketType === "FH_OverUnder") ? item.odds?.filter((item) => item.marketType === "FH_OverUnder") : [])

            setOddTop1X2FHOld(oddTop1X2FH)
            setOddTop1X2FH(item.odds?.filter((item) => item.marketType === "FH_1X2") ? item.odds?.filter((item) => item.marketType === "FH_1X2") : [])

            setCorrectScoreFHOld(correctScoreFH)
            setCorrectScoreFH(item.odds?.filter((item) => item.marketType === "FH_CorrectScore") ? item.odds?.filter((item) => item.marketType === "FH_CorrectScore") : [])

            setTotalCornersFHOverUnderOld(totalCornersFHOverUnder)
            setTotalCornersFHOverUnder(item.odds?.filter((item) => item.marketType === "TotalCorners_FH_OverUnder") ? item.odds?.filter((item) => item.marketType === "TotalCorners_FH_OverUnder") : [])

            setTotalCornersFHHandicapOld(totalCornersFHHandicap)
            setTotalCornersFHHandicap(item.odds?.filter((item) => item.marketType === "TotalCorners_FH_Handicap") ? item.odds?.filter((item) => item.marketType === "TotalCorners_FH_Handicap") : [])
        } else {
            setOddTopHDPFHOld([])
            setOddTopHDPFH([])
            setOddTopOUFHOld([])
            setOddTopOUFH([])
            setOddTop1X2FHOld([])
            setOddTop1X2FH([])
            setCorrectScoreFHOld([])
            setCorrectScoreFH([])
            setTotalCornersFHOverUnder([])
            setTotalCornersFHHandicap([])
        }

        setOddEvenOld(oddEven)
        setOddEven(item.odds?.filter((item) => item.marketType === "OddEven") ? item.odds?.filter((item) => item.marketType === "OddEven") : [])

        setTotalGoalOld(totalGoal)
        setTotalGoal(item.odds?.filter((item) => item.marketType === "TotalGoal") ? item.odds?.filter((item) => item.marketType === "TotalGoal") : [])

        setDoubleChanceOld(doubleChance)
        setDoubleChance(item.odds?.filter((item) => item.marketType === "DoubleChance") ? item.odds?.filter((item) => item.marketType === "DoubleChance") : [])

        setCorrectScoreOld(correctScore)
        setCorrectScore(item.odds?.filter((item) => item.marketType === "CorrectScore") ? item.odds?.filter((item) => item.marketType === "CorrectScore") : [])

        setTotalCornersOverUnderOld(totalCornersOverUnder)
        setTotalCornersOverUnder(item.odds?.filter((item) => item.marketType === "TotalCorners_OverUnder") ? item.odds?.filter((item) => item.marketType === "TotalCorners_OverUnder") : [])

        setTotalCornersHandicapOld(totalCornersHandicap)
        setTotalCornersHandicap(item.odds?.filter((item) => item.marketType === "TotalCorners_Handicap") ? item.odds?.filter((item) => item.marketType === "TotalCorners_Handicap") : [])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item])
    return (
        <>
            <div className='flex borderLine detailTop'>
                <div className='pb-[10px] w-full'>
                    <div>
                        {blockAllOdds ? "" :
                            <div className='flex flex-col w-full'>
                                <div className="gameRatio flex w-full">
                                    <div className="homeRaito w-[50%] flex justify-between">
                                        {/* <SoccerTopA1x2F_V2 /> */}
                                        <SoccerTopHDPF_V2
                                            live={true}
                                            homeTeam={homeTeam}
                                            awayTeam={awayTeam}
                                            homeScore={homeScore ? Number(homeScore) : 0}
                                            awayScore={awayScore ? Number(awayScore) : 0}
                                            item={oddTopHDPF}
                                            oddsOld={oddTopHDPFOld}
                                            listChooseBet={listChooseBet}
                                            listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                            chooseBetTop={chooseBetSoccerTopHDPF}
                                            checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                            updateOdds={updateOdds.current}
                                        />
                                        <SoccerTopOUF_V2
                                            live={true}
                                            homeTeam={homeTeam}
                                            awayTeam={awayTeam}
                                            homeScore={homeScore ? Number(homeScore) : 0}
                                            awayScore={awayScore ? Number(awayScore) : 0}
                                            item={oddTopOUF}
                                            oddsOld={oddTopOUFOld}
                                            listChooseBet={listChooseBet}
                                            listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                            chooseBetTop={chooseBetSoccerTopOUF}
                                            checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                            updateOdds={updateOdds.current}
                                        />
                                        <SoccerTop1x2F_V2
                                            live={true}
                                            homeTeam={homeTeam}
                                            awayTeam={awayTeam}
                                            homeScore={homeScore ? Number(homeScore) : 0}
                                            awayScore={awayScore ? Number(awayScore) : 0}
                                            item={oddTop1X2}
                                            oddsOld={oddTop1X2Old}
                                            listChooseBet={listChooseBet}
                                            listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                            chooseBetTop={chooseBetSoccerTop1x2F}
                                            checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                            updateOdds={updateOdds.current}
                                        />
                                    </div>
                                    <div className="awayRaito w-[50%] flex justify-between">
                                        <SoccerTopHDPH_V2
                                            homeTeam={homeTeam}
                                            awayTeam={awayTeam}
                                            homeScore={homeScore ? Number(homeScore) : 0}
                                            awayScore={awayScore ? Number(awayScore) : 0}
                                            item={oddTopHDPFH}
                                            oddsOld={oddTopHDPFHOld}
                                            listChooseBet={listChooseBet}
                                            listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                            chooseBetTop={chooseBetSoccerTopHDPH}
                                            checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                            updateOdds={updateOdds.current}
                                        />
                                        <SoccerTopOUH_V2
                                            homeTeam={homeTeam}
                                            awayTeam={awayTeam}
                                            homeScore={homeScore ? Number(homeScore) : 0}
                                            awayScore={awayScore ? Number(awayScore) : 0}
                                            item={oddTopOUFH}
                                            oddsOld={oddTopOUFHOld}
                                            listChooseBet={listChooseBet}
                                            listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                            chooseBetTop={chooseBetSoccerTopOUH}
                                            checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                            updateOdds={updateOdds.current}
                                        />
                                        <SoccerTop1x2H_V2
                                            homeTeam={homeTeam}
                                            awayTeam={awayTeam}
                                            homeScore={homeScore ? Number(homeScore) : 0}
                                            awayScore={awayScore ? Number(awayScore) : 0}
                                            item={oddTop1X2FH}
                                            oddsOld={oddTop1X2FHOld}
                                            listChooseBet={listChooseBet}
                                            listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                            chooseBetTop={chooseBetSoccerTop1x2H}
                                            checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                            updateOdds={updateOdds.current}
                                        />
                                    </div>
                                </div>
                                {/* {oddEven.length > 0 || totalGoal.length > 0 || doubleChance.length > 0 || correctScore.length > 0 || correctScoreFH.length > 0 ? ( */}
                                <Collapse
                                    className='collapse-soccer-live w-full !border !border-[#202531]'
                                    expandIcon={
                                        (expanded) => (
                                            expanded.isActive ? (
                                                <span className='!text-base fw-[400] !text-[#C1C9E1]'>View less <MinusOutlined className='pl-2' /></span>
                                            ) : (
                                                <span className='!text-base fw-[400] !text-[#C1C9E1]'>View more {countNumberSubbet !== 0 ? `(${countNumberSubbet})` : ""}<PlusOutlined className='pl-2' /></span>
                                            )
                                        )


                                    }
                                    items={[{
                                        key: item.id,
                                        children:
                                            <div className='flex flex-col'>
                                                <div className="gameRatio flex w-full">
                                                    <div className="homeRaito w-[50%] flex justify-between">
                                                        {/* <SoccerTopA1x2F_V2 /> */}
                                                        <SoccerTopHDPFSideBet
                                                            live={true}
                                                            homeTeam={homeTeam}
                                                            awayTeam={awayTeam}
                                                            homeScore={homeScore ? Number(homeScore) : 0}
                                                            awayScore={awayScore ? Number(awayScore) : 0}
                                                            item={oddTopHDPF}
                                                            oddsOld={oddTopHDPFOld}
                                                            listChooseBet={listChooseBet}
                                                            listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                                            chooseBetTop={chooseBetSoccerTopHDPF}
                                                            checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                                            updateOdds={updateOdds.current}
                                                        />
                                                        <SoccerTopOUFSideBet
                                                            live={true}
                                                            homeTeam={homeTeam}
                                                            awayTeam={awayTeam}
                                                            homeScore={homeScore ? Number(homeScore) : 0}
                                                            awayScore={awayScore ? Number(awayScore) : 0}
                                                            item={oddTopOUF}
                                                            oddsOld={oddTopOUFOld}
                                                            listChooseBet={listChooseBet}
                                                            listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                                            chooseBetTop={chooseBetSoccerTopOUF}
                                                            checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                                            updateOdds={updateOdds.current}
                                                        />
                                                        <SoccerTop1x2FSideBet
                                                            live={true}
                                                            homeTeam={homeTeam}
                                                            awayTeam={awayTeam}
                                                            homeScore={homeScore ? Number(homeScore) : 0}
                                                            awayScore={awayScore ? Number(awayScore) : 0}
                                                            item={oddTop1X2}
                                                            oddsOld={oddTop1X2Old}
                                                            listChooseBet={listChooseBet}
                                                            listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                                            chooseBetTop={chooseBetSoccerTop1x2F}
                                                            checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                                            updateOdds={updateOdds.current}
                                                        />
                                                    </div>
                                                    <div className="awayRaito w-[50%] flex justify-between">
                                                        <SoccerTopHDPHSideBet
                                                            homeTeam={homeTeam}
                                                            awayTeam={awayTeam}
                                                            homeScore={homeScore ? Number(homeScore) : 0}
                                                            awayScore={awayScore ? Number(awayScore) : 0}
                                                            item={oddTopHDPFH}
                                                            oddsOld={oddTopHDPFHOld}
                                                            listChooseBet={listChooseBet}
                                                            listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                                            chooseBetTop={chooseBetSoccerTopHDPH}
                                                            checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                                            updateOdds={updateOdds.current}
                                                        />
                                                        <SoccerTopOUHSideBet
                                                            homeTeam={homeTeam}
                                                            awayTeam={awayTeam}
                                                            homeScore={homeScore ? Number(homeScore) : 0}
                                                            awayScore={awayScore ? Number(awayScore) : 0}
                                                            item={oddTopOUFH}
                                                            oddsOld={oddTopOUFHOld}
                                                            listChooseBet={listChooseBet}
                                                            listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                                            chooseBetTop={chooseBetSoccerTopOUH}
                                                            checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                                            updateOdds={updateOdds.current}
                                                        />
                                                        <SoccerTop1x2HSideBet
                                                            homeTeam={homeTeam}
                                                            awayTeam={awayTeam}
                                                            homeScore={homeScore ? Number(homeScore) : 0}
                                                            awayScore={awayScore ? Number(awayScore) : 0}
                                                            item={oddTop1X2FH}
                                                            oddsOld={oddTop1X2FHOld}
                                                            listChooseBet={listChooseBet}
                                                            listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                                            chooseBetTop={chooseBetSoccerTop1x2H}
                                                            checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                                            updateOdds={updateOdds.current}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="gameRatio flex">
                                                    <div className="homeRaito flex flex-col w-full">
                                                        <StatusBarSoccerMoreTab
                                                            oddsEven={oddEven}
                                                            oddsEvenOld={oddEvenOld}
                                                            totalGoal={totalGoal}
                                                            totalGoalOld={totalGoalOld}
                                                            doubleChance={doubleChance}
                                                            doubleChanceOld={doubleChanceOld}
                                                            homeTeam={homeTeam}
                                                            awayTeam={awayTeam}
                                                            homeScore={homeScore ? Number(homeScore) : 0}
                                                            awayScore={awayScore ? Number(awayScore) : 0}
                                                            listChooseBet={listChooseBet}
                                                            listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                                            chooseBetTop={chooseBetSoccerTotalGoal}
                                                            chooseBetTopDoubleChance={chooseBetSoccerDoubleChance}
                                                            chooseBetSoccerOEF={chooseBetSoccerOEF}
                                                            checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                                            updateOdds={updateOdds.current}
                                                        />
                                                        <StatusBarSoccerCorrectScoreFH
                                                            correctScoreFH={correctScoreFH}
                                                            correctScoreFHOld={correctScoreFHOld}
                                                            homeTeam={homeTeam}
                                                            awayTeam={awayTeam}
                                                            homeScore={homeScore ? Number(homeScore) : 0}
                                                            awayScore={awayScore ? Number(awayScore) : 0}
                                                            listChooseBet={listChooseBet}
                                                            listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                                            chooseBetTop={chooseBetSoccerCorrectScoreFH}
                                                            checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                                            updateOdds={updateOdds.current}
                                                        />
                                                        <StatusBarSoccerCorrectScore
                                                            correctScore={correctScore}
                                                            correctScoreOld={correctScoreOld}
                                                            homeTeam={homeTeam}
                                                            awayTeam={awayTeam}
                                                            homeScore={homeScore ? Number(homeScore) : 0}
                                                            awayScore={awayScore ? Number(awayScore) : 0}
                                                            listChooseBet={listChooseBet}
                                                            listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                                            chooseBetTop={chooseBetSoccerCorrectScore}
                                                            checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                                            updateOdds={updateOdds.current}
                                                        />

                                                        <StatusBarSoccerTotalCorners
                                                            totalGoal={totalGoal}
                                                            totalGoalOld={totalGoalOld}

                                                            totalCornersOverUnder={totalCornersOverUnder}
                                                            totalCornersHandicap={totalCornersHandicap}
                                                            totalCornersFHOverUnder={totalCornersFHOverUnder}
                                                            totalCornersFHHandicap={totalCornersFHHandicap}
                                                            totalCornersOverUnderOld={totalCornersOverUnderOld}
                                                            totalCornersHandicapOld={totalCornersHandicapOld}
                                                            totalCornersFHOverUnderOld={totalCornersFHOverUnderOld}
                                                            totalCornersFHHandicapOld={totalCornersFHHandicapOld}

                                                            homeTeam={homeTeam}
                                                            awayTeam={awayTeam}
                                                            homeScoreTotalCorner={homeScoreTotalCorner}
                                                            awayScoreTotalCorner={awayScoreTotalCorner}
                                                            listChooseBet={listChooseBet}
                                                            listAddStrightBetsSlipSoccer={listAddStrightBetsSlipSoccer}
                                                            chooseBetTop={chooseBetSoccerTotalCornersOverUnder}
                                                            chooseBetTopTotalCornersHandicap={chooseBetSoccerTotalCornersHandicap}
                                                            chooseBetTopTotalCornersFHHandicap={chooseBetTopTotalCornersFHHandicap}
                                                            chooseBetTopTotalCornersFHOU={chooseBetTopTotalCornersFHOU}
                                                            checkUpdateStrightBetsSlip={checkUpdateStrightBetsSlip}
                                                            updateOdds={updateOdds.current}
                                                        />

                                                        {/* <StatusBarSoccerHTFT
                                    HTFT={HTFT}
                                    homeTeam={homeTeam}
                                    awayTeam={awayTeam}
                                    listChooseBet={listChooseBet}
                                    chooseBetTop={chooseBetSoccerHTFT}
                                    updateOdds={updateOdds.current}
                                />
                                <StatusBarSoccerFGLG
                                    FGLG={FGLG}
                                    homeTeam={homeTeam}
                                    awayTeam={awayTeam}
                                    listChooseBet={listChooseBet}
                                    chooseBetTop={chooseBetSoccerFGLG}
                                    updateOdds={updateOdds.current}
                                /> */}

                                                    </div>
                                                </div>
                                            </div>

                                    }]}
                                />
                                {/* ) : ""} */}


                            </div>
                        }


                    </div>
                </div>
            </div >
        </>
    );
};

export default SoccerTopLiveV3;