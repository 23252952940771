import React from "react";
import { CloseOutlined } from "@mui/icons-material";
import { InfoOutlined } from "@ant-design/icons";
import { Checkbox } from "antd";
import { InfoBetTeaserTMP } from "../../../../../../api/betting/bettingApi";
import { ElipseRed } from "../../../../../../components/imgExport";
interface Props {
    index: number
    item: InfoBetTeaserTMP
    listTeaserBetsSlip: InfoBetTeaserTMP[]

    rStrightBetsSlipByIndex: (value: number) => void
    setListTeaserBetsSlip?: (value: InfoBetTeaserTMP[]) => void

}
const ItemTeaser: React.FC<Props> = ({
    index, item, listTeaserBetsSlip,
    rStrightBetsSlipByIndex, setListTeaserBetsSlip
}) => {
    const handleCheck = (e: boolean) => {
        var arr = listTeaserBetsSlip.map((item, indexc) => (indexc === index ? { ...item, isCheck: e } : item));
        setListTeaserBetsSlip?.(arr)
    }

    return (
        <div>
            <div
            >
                <div className="flex justify-end gap-5 pr-6">
                    <span className="text-[#D5D5D5] text-[11px]">Point</span>
                    <span className="text-[#D5D5D5] text-[11px]">New Line</span>
                </div>
                <div className={`flex justify-between pb-2 pt-4`}>

                    <div className="flex">
                        <Checkbox
                            className="flex pb-5"
                            checked={item.isCheck}
                            onChange={(e) => handleCheck(!item.isCheck)}
                        ></Checkbox>
                        <div className="flex flex-col">
                            <span className='font-[700] text-black pl-3'>
                                {item.teamChoose}
                            </span>
                            <span className='font-bold text-black pl-3 text-left'>
                                {item?.scoreChoose.split(" ").length === 3 ? item?.scoreChoose.split(" ")[1] : item?.scoreChoose.split(" ")[0]}
                            </span>
                        </div>

                    </div>
                    <div className='flex'>
                        <span className='font-[500] text-black'>
                            +{item.point}
                        </span>
                        <span className='ml-[18px] font-[500] text-black !w-[50px]'>{item.newLine} </span>
                        <CloseOutlined
                            className='text-[#898989]'
                            onClick={() => rStrightBetsSlipByIndex?.(index)}
                        />
                    </div>
                </div>

                <div className="flex gap-1">
                    <div className={`${item.isWarning ? "" : "hidden"} flex items-center pb-6`}>
                        <InfoOutlined className="bg-[#ffc700] p-[1px] rounded-sm" />
                    </div>
                    <div>
                        <div className='flex justify-start pl-2 pb-2'>
                            <span className='text-[#898989] text-xs'>{item?.lineBetting}</span>
                        </div>
                        <div className='flex justify-start pl-2'>
                            <span className='font-[500] text-black text-xs'>{item.game}</span>
                        </div>
                    </div>
                </div>
                {item?.live ? (
                    <span className='font-bold flex pt-4 betClippers'>
                        <span className='flex w-fit bg-[#ffcccc] px-2 rounded-md text-red-500'>
                            <img className="pr-2" src={ElipseRed} alt="ElipseRed" /> LIVE
                        </span>
                    </span>
                ) : ''}
            </div>
        </div>
    )
}

export default ItemTeaser;