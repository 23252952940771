import React, { useState } from 'react';
import '../GameList/gameList.css'
import { image404, LogoWithoutText, video_2 } from '../../../../../components/imgExport';
import { Tooltip } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { ChooseBet } from '../../../../../api/betting/bettingApi';
import { InfoBetMGM } from '../../../../../api/homePage/homePage_V2Api';
import { covertDataOptionMarkets } from '../../../../../until';
import { ICON_TEAM } from '../../../../../env';
interface GameDetailProps {
  sport?: string
  tournament: string
  item: InfoBetMGM
  listChooseBet: ChooseBet[]
  chooseBet?: (
    tournament: string, game: string, teamChoose: string, scoreChoose: string, lineBetting: "Spread" | "Money" | "Total" | "First Half Spread" | "First Half Money" | "First Half Total" | "First 5 Innings Spread" | "First 5 Innings Total" | "First 5 Innings Money", dateEvent: string, live?: boolean, id?: number | string, idGame?: number | string, optionId?: number | string, homeLiveScore?: number, awayLiveScore?: number, dateEventServer?: string
  ) => void
  decimal: string
  inputFilter?: string
}

function formatDate(UTC: string) {
  let d = new Date(UTC).toLocaleDateString()
  return d
}

function formatTime(UTC: string) {
  let d = new Date(UTC).toLocaleTimeString()
  return d
}

const GameDeTail_V2: React.FC<GameDetailProps> = ({
  sport, tournament, item, listChooseBet, decimal, inputFilter,
  chooseBet,

}) => {

  const [gameLine, setGameLine] = useState<number>(1)
  const { startDate, participants, optionMarkets } = item
  var startingPitcherHomeTeam = item.addons?.fixtureStatistics?.startingPitcher?.homeTeam ? item.addons?.fixtureStatistics?.startingPitcher?.homeTeam.toString().split(" ") : ""
  var startingPitcherAwayTeam = item.addons?.fixtureStatistics?.startingPitcher?.awayTeam ? item.addons?.fixtureStatistics?.startingPitcher?.awayTeam.toString().split(" ") : ""


  var startingPitcherHomeTeamFinal: any
  var startingPitcherAwayTeamFinal: any
  for (let index = 0; index < startingPitcherHomeTeam.length; index++) {
    if (index === 0) {
      startingPitcherHomeTeamFinal = startingPitcherHomeTeam[index][0] + "."
    } else {
      startingPitcherHomeTeamFinal = startingPitcherHomeTeamFinal + " " + startingPitcherHomeTeam[index]
    }
  }

  for (let index = 0; index < startingPitcherAwayTeam.length; index++) {
    if (index === 0) {
      startingPitcherAwayTeamFinal = startingPitcherAwayTeam[index][0] + "."
    } else {
      startingPitcherAwayTeamFinal = startingPitcherAwayTeamFinal + " " + startingPitcherAwayTeam[index]
    }
  }

  // const { games, startDate, participants, optionMarkets } = item
  if (optionMarkets.length === 0) {
    // const { spread, totals, moneyLine } = covertDataGames(games)  
  } else {

  }
  const { spread, totals, moneyLine, FHspread, FHtotals, FHmoneyLine, spread5Innings, totals5Innings, moneyLine5Innings } = covertDataOptionMarkets(optionMarkets)
  var spreadRunLine: any
  if (!spread) {
    spreadRunLine = optionMarkets.filter((market) => market.name.value === "Run Line Spread")[0]
  }

  const checkSelect = (team: string, lineBetting: "Spread" | "Money" | "Total" | "First Half Spread" | "First Half Money" | "First Half Total" | "First 5 Innings Spread" | "First 5 Innings Total" | "First 5 Innings Money") => {
    const isDuplicate = listChooseBet.some(
      (bet) => bet.teamChoose?.indexOf(team) !== -1 && bet.lineBetting === lineBetting && bet.dateEvent === (formatDate(startDate) + "|" + formatTime(startDate))
    );
    return isDuplicate ? "!bg-[#D4B962]" : ""
  }

  const handelBet = (teamChoose: string, scoreChoose: string, lineBetting: "Spread" | "Money" | "Total" | "First Half Spread" | "First Half Money" | "First Half Total" | "First 5 Innings Spread" | "First 5 Innings Total" | "First 5 Innings Money", id?: number | string) => {
    const game = participants[1].name.value + " / " + participants[0].name.value
    chooseBet?.(tournament, game, teamChoose, scoreChoose, lineBetting, (formatDate(startDate) + "|" + formatTime(startDate)), false, item.id, item.id, id, 0, 0, startDate.toString())
  }
  return (
    <div className='flex flex-col'>
      <div className='flex justify-between gap-2'>
        <div className='w-[220px] rounded-xl border border-[#2B313E] bg-[#202531] flex items-center justify-between px-2 py-1'>
          <div className='flex items-center gap-2'>
            <img src={video_2} alt="" />
            <span className="fw-[500] text-base text-[#FFFFFF]">{formatDate(startDate)}</span>
          </div>
          <div className='w-[18px] border border-[#2B303D]'></div>
          <span className='text-[#FFFFFF] fw-[500] text-base'> {formatTime(startDate)}</span>
        </div>
        {(sport === "NFL" || sport === "NBA") && (FHspread || FHtotals || FHmoneyLine) && (
          <div className='rounded-xl border border-[#2B313E] bg-[#202531] flex items-center justify-between px-2 py-1 gap-3'>
            <span className={`text-base fw-[500] text-center ${gameLine === 1 ? "text-[#D4B962]" : "text-[#C1C9E1]"}`} onClick={() => setGameLine(1)}>Full Game</span>
            <span className={`text-base fw-[500] text-center ${gameLine === 2 ? "text-[#D4B962]" : "text-[#C1C9E1]"}`} onClick={() => setGameLine(2)}>1st Half</span>
          </div>
        )}
        {(sport === "MLB") && (spread5Innings || totals5Innings || moneyLine5Innings) && (
          <div className='rounded-xl border border-[#2B313E] bg-[#202531] flex items-center justify-between px-2 py-1 gap-3'>
            <span className={`text-base fw-[500] text-center ${gameLine === 1 ? "text-[#D4B962]" : "text-[#C1C9E1]"}`} onClick={() => setGameLine(1)}>Full Game</span>
            <span className={`text-base fw-[500] text-center ${gameLine === 3 ? "text-[#D4B962]" : "text-[#C1C9E1]"}`} onClick={() => setGameLine(3)}>First 5 Innings</span>
          </div>
        )}
        <div className='bg-header rounded-tl-xl rounded-tr-xl flex justify-end py-3 gap-1'>
          <span className='text-base fw-[500] text-[#C1C9E1] text-center w-[104px]'>SPREAD</span>
          <span className='text-base fw-[500] text-[#C1C9E1] text-center w-[104px]'>TOTAL</span>
          <span className='text-base fw-[500] text-[#C1C9E1] text-center w-[104px]'>MONEY LINE</span>
        </div>
      </div>

      <div className={` flex items-center w-full justify-between pb-2 ${inputFilter && inputFilter !== "" && (participants[0].name.value.toLowerCase().indexOf(inputFilter.toLowerCase()) === -1 && participants[1].name.value.toLowerCase().indexOf(inputFilter.toLowerCase()) === -1) ? "hidden" : ""}`}
      >
        <Tooltip placement="right">
          <div className="gameTime bg-[#131620]"
          // onClick={() => navigator("/detailPage")}
          >
            <div className='flex flex-col justify-between gap-2'>

              <div className='flex flex-col items-start gap-3'>

                <div className='flex items-center gap-2 h-[56px]'>
                  <img
                    className='w-[30px] h-[30px]'
                    // src={LogoWithoutText}
                    src={item.sport.id ? (ICON_TEAM + item.sport.id + `/` + participants[0].image?.logo) : (ICON_TEAM + `7/` + participants[0].image?.logo)}
                    alt="team1"
                    onError={(e) => {
                      e.currentTarget.src = LogoWithoutText;
                    }} />
                  <div className='flex flex-col items-start'>
                    <span className='flex item-start text-base text-[#E4E7F1] fw-[#E4E7F1]'>{participants[0].name.short}</span>
                    <div className='flex items-start'>
                      <span className='text-sm text-[#D4B962] fw-[#E4E7F1]'>{startingPitcherAwayTeamFinal}</span>
                    </div>
                  </div>
                </div>


                {/* <span className='text-[#D4B962] text-base pl-2'>VS</span> */}
                <div className='flex items-center gap-2 h-[56px]'>
                  <img
                    className=' w-[30px] h-[30px]'
                    // src={LogoWithoutText}
                    src={item.sport.id ? (ICON_TEAM + item.sport.id + `/` + participants[1].image?.logo) : (ICON_TEAM + `7/` + participants[1].image?.logo)}
                    alt="team1"
                    onError={(e) => {
                      e.currentTarget.src = LogoWithoutText;
                    }} />
                  <div className='flex flex-col'>
                    <span className='flex item-start text-base text-[#E4E7F1] fw-[#E4E7F1]'>{participants[1].name.short}</span>
                    <div className='flex items-start'>
                      <span className='text-sm text-[#D4B962] fw-[#E4E7F1]'>{startingPitcherHomeTeamFinal}</span>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            {/* <div className="timeDetail pb-5">
              <span className='font-bold flex'>
                <img className="pr-1" src={Ellipse} alt="Ellipse" />{formatDate(startDate)} | {formatTime(startDate)}
              </span>
            </div> */}
            {/* <div className='flex flex-col'>
              <span
                className='flex h-7 font-bold mb-[20px]'
              >
                <img
                  className='pr-2 w-[36px] h-[36px]'
                  src={item.sport.id ? (ICON_TEAM + item.sport.id + `/` + participants[0].image?.logo) : (ICON_TEAM + `7/` + participants[0].image?.logo)}
                  alt="team1"
                  onError={(e) => {
                    e.currentTarget.src = image404;
                  }}
                />
                <span className='pl-1 flex items-center h-[36px] text-[15px]'>{participants[0].name.short}</span>
              </span>
              <span className="bg-[#E7E7E7] h-[1px]"></span>
              <span className='flex h-7 font-bold mt-[20px]'>
                <img
                  className='pr-2 w-[36px] h-[36px]'
                  src={item.sport.id ? (ICON_TEAM + item.sport.id + `/` + participants[1].image?.logo) : (ICON_TEAM + `7/` + participants[1].image?.logo)}
                  alt="team2"
                  onError={(e) => {
                    e.currentTarget.src = image404;
                  }}
                />
                <span className='pl-1 flex items-center h-[36px] text-[15px]'>{participants[1].name.short}</span>
              </span>
            </div> */}
          </div>
        </Tooltip>
        {item.subtractTimeClient < 60000 ?
          <div className="gameRatio pl-10">
            <div className="homeRaito flex">
              <div className='flex justify-center items-center text-[#E4E7F1] text-[16px] w-8'>
                <b className='pr-1 text-[#E4E7F1]'>{item.scoreboard?.totalPoints?.player1?.[255]?.toString() ? item.scoreboard?.totalPoints?.player1?.[255] : item.scoreboard?.scoreDetailed?.player1?.[255]}</b>
              </div>
              <div className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col relative `}>
                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
              </div>
              <div className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col relative `}>
                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
              </div>
              <div className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col relative `}>
                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
              </div>
            </div>

            <div className="awayRaito flex">
              <div className='flex justify-center items-center text-[#E4E7F1] text-[16px] w-8'>
                <b className='pr-1 text-[#E4E7F1]'>{item.scoreboard?.totalPoints?.player2?.[255]?.toString() ? item.scoreboard?.totalPoints?.player2?.[255] : (item.scoreboard?.scoreDetailed?.player2?.[255])}</b>
              </div>
              <div className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col relative `}>
                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
              </div>
              <div className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col relative `}>
                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
              </div>
              <div className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col relative `}>
                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
              </div>
            </div>
          </div>
          :
          <>
            <div className='flex flex-col'>
              {gameLine === 1 ?
                //Full game
                <>
                  <div className="gameRatio pl-10">
                    <div className="homeRaito flex">
                      {spread?.status === 'Visible' || spreadRunLine?.status === 'Visible' ? (
                        <div
                          className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col ${checkSelect(participants[0].name.value, "Spread")}`}
                        >
                          {
                            spread?.options[0]?.status === 'Visible' && spread?.options[0]?.attr ?
                              <div className='w-full h-full flex flex-col justify-center items-center'
                                onClick={() => handelBet?.(participants[0].name.value, spread?.options[0]?.attr + " " + (decimal !== 'American' ? spread?.options[0]?.price?.odds : spread?.options[0]?.price?.americanOdds), "Spread", spread?.options[0]?.id)}
                              >
                                <p className='text-[#E4E7F1] text-[16px]'>{`${spread?.options[0]?.attr}`}</p>
                                <p><b className='!text-[#E4E7F1] text-[18px]'>{`${(decimal !== 'American' ? spread?.options[0]?.price?.odds.toFixed(2) : (Number(spread?.options[0]?.price?.americanOdds) > 0 ? `+${spread?.options[0]?.price?.americanOdds}` : spread?.options[0]?.price?.americanOdds))}`}</b></p>
                              </div> :
                              spreadRunLine?.options[0]?.status === 'Visible' && spreadRunLine?.options[0]?.attr ?
                                <div className='w-full h-full flex flex-col justify-center items-center'
                                  onClick={() => handelBet?.(participants[0].name.value, spreadRunLine?.options[0]?.attr + " " + (decimal !== 'American' ? spreadRunLine?.options[0]?.price?.odds : spreadRunLine?.options[0]?.price?.americanOdds), "Spread", spreadRunLine?.options[0]?.id)}
                                >
                                  <p className='text-[#E4E7F1] text-[16px]'>{`${spreadRunLine?.options[0]?.attr}`}</p>
                                  <p><b className='!text-[#E4E7F1] text-[18px]'>{`${(decimal !== 'American' ? spreadRunLine?.options[0]?.price?.odds.toFixed(2) : (Number(spreadRunLine?.options[0]?.price?.americanOdds) > 0 ? `+${spreadRunLine?.options[0]?.price?.americanOdds}` : spreadRunLine?.options[0]?.price?.americanOdds))}`}</b></p>
                                </div> :
                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                          }
                        </div>
                      ) : (
                        <div
                          className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col ${checkSelect(participants[0].name.value, "Spread")}`}
                        >
                          <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                        </div>
                      )}

                      {totals?.status === 'Visible' ? (
                        <div
                          className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col ${checkSelect(participants[0].name.value, "Total")}`}
                        >
                          {
                            totals?.options[0]?.status === 'Visible' && totals?.options[0]?.name.value ?
                              <div className='w-full h-full flex flex-col justify-center items-center'
                                onClick={() => handelBet?.(participants[0].name.value, totals?.options[0]?.name.value + " " + (decimal !== 'American' ? totals?.options[0]?.price.odds : totals?.options[0]?.price.americanOdds), "Total", totals?.options[0]?.id)}
                              >
                                <p className='text-[#E4E7F1] text-[16px]'>{`${totals?.options[0]?.name.value}`}</p>
                                <p><b className='text-[#E4E7F1] text-[18px]'>{`${(decimal !== 'American' ? totals?.options[0]?.price.odds.toFixed(2) : (Number(totals?.options[0]?.price.americanOdds) > 0 ? `+${totals?.options[0]?.price.americanOdds}` : totals?.options[0]?.price.americanOdds))}`}</b></p>
                              </div> :
                              <p className='text-[#E4E7F1]'><LockOutlined /></p>
                          }
                        </div>
                      ) : (
                        <div
                          className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] flex items-center flex-col ${checkSelect(participants[0].name.value, "Total")}`}
                        >
                          <p className='text-[#E4E7F1]'><LockOutlined /></p>
                        </div>
                      )}

                      {moneyLine?.status === 'Visible' ? (
                        <div
                          className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col ${checkSelect(participants[0].name.value, "Money")}`}
                        >
                          {
                            moneyLine?.options[0]?.status === 'Visible' && moneyLine?.options[0]?.price?.odds ?
                              <div className='w-full h-full flex flex-col justify-center items-center'
                                onClick={() => handelBet?.(participants[0].name.value, (decimal !== 'American' ? moneyLine?.options[0]?.price.odds?.toString() : moneyLine?.options[0]?.price.americanOdds?.toString()), "Money", moneyLine?.options[0]?.id)}
                              >
                                <b className='text-[#E4E7F1] text-[18px]'>{(decimal !== 'American' ? moneyLine?.options[0]?.price.odds.toFixed(2) : (Number(moneyLine?.options[0]?.price.americanOdds) > 0 ? `+${moneyLine?.options[0]?.price.americanOdds}` : moneyLine?.options[0]?.price.americanOdds))}</b>
                              </div> :
                              <p className='text-[#E4E7F1]'><LockOutlined /></p>
                          }
                        </div>
                      ) : (
                        <div
                          className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col ${checkSelect(participants[0].name.value, "Money")}`}
                        >
                          <p className='text-[#E4E7F1]'><LockOutlined /></p>
                        </div>
                      )}


                    </div>

                    <div className="awayRaito flex">
                      {spread?.status === 'Visible' || spreadRunLine?.status === 'Visible' ? (
                        <div
                          className={` my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col ${checkSelect(participants[1].name.value, "Spread")}`}
                        >
                          {
                            spread?.options[1]?.status === 'Visible' && spread?.options[1]?.attr ?
                              <div className='w-full h-full flex flex-col justify-center items-center'
                                onClick={() => handelBet?.(participants[1].name.value, spread?.options[1]?.attr + " " + (decimal !== 'American' ? spread?.options[1]?.price?.odds : spread?.options[1]?.price?.americanOdds), "Spread", spread?.options[1]?.id)}
                              >
                                <p className='text-[#E4E7F1] text-[16px]'>{`${spread?.options[1]?.attr}`}</p>
                                <p><b className='text-[#E4E7F1] text-[18px]'>{`${(decimal !== 'American' ? spread?.options[1]?.price?.odds.toFixed(2) : (Number(spread?.options[1]?.price?.americanOdds) > 0 ? `+${spread?.options[1]?.price?.americanOdds}` : spread?.options[1]?.price?.americanOdds))}`}</b></p>
                              </div> :
                              spreadRunLine?.options[1]?.status === 'Visible' && spreadRunLine?.options[1]?.attr ?
                                <div className='w-full h-full flex flex-col justify-center items-center'
                                  onClick={() => handelBet?.(participants[1].name.value, spreadRunLine?.options[1]?.attr + " " + (decimal !== 'American' ? spreadRunLine?.options[1]?.price?.odds : (Number(spreadRunLine?.options[1]?.price?.americanOdds) > 0 ? `+${spreadRunLine?.options[1]?.price?.americanOdds}` : spreadRunLine?.options[1]?.price?.americanOdds)), "Spread", spreadRunLine?.options[1]?.id)}
                                >
                                  <p className='text-[#E4E7F1] text-[16px]'>{`${spreadRunLine?.options[1]?.attr}`}</p>
                                  <p><b className='text-[#E4E7F1] text-[18px]'>{`${(decimal !== 'American' ? spreadRunLine?.options[1]?.price?.odds.toFixed(2) : spreadRunLine?.options[1]?.price?.americanOdds)}`}</b></p>
                                </div> :
                                <p className='text-[#E4E7F1]'><LockOutlined /></p>
                          }
                        </div>
                      ) : (
                        <div
                          className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col ${checkSelect(participants[1].name.value, "Spread")}`}
                        >
                          <p className='text-[#E4E7F1]'><LockOutlined /></p>
                        </div>
                      )}

                      {totals?.status === 'Visible' ? (
                        <div
                          className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col ${checkSelect(participants[1].name.value, "Total")}`}
                        >
                          {
                            totals?.options[1]?.status === 'Visible' && totals?.options[1]?.name.value ?
                              <div className='w-full h-full flex flex-col justify-center items-center'
                                onClick={() => handelBet?.(participants[1].name.value, totals?.options[1]?.name.value + " " + (decimal !== 'American' ? totals?.options[1]?.price.odds : totals?.options[1]?.price.americanOdds), "Total", totals?.options[1]?.id)}
                              >
                                <p className='text-[#E4E7F1] text-[16px]'>{`${totals?.options[1]?.name.value}`}</p>
                                <p><b className='text-[#E4E7F1] text-[18px]'>{`${(decimal !== 'American' ? totals?.options[1]?.price.odds.toFixed(2) : (Number(totals?.options[1]?.price.americanOdds) > 0 ? `+${totals?.options[1]?.price.americanOdds}` : totals?.options[1]?.price.americanOdds))}`}</b></p>
                              </div> :
                              <p className='text-[#E4E7F1]'><LockOutlined /></p>
                          }
                        </div>
                      ) : (
                        <div
                          className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col ${checkSelect(participants[1].name.value, "Total")}`}
                        >
                          <p className='text-[#E4E7F1]'><LockOutlined /></p>
                        </div>
                      )}

                      {moneyLine?.status === 'Visible' ? (
                        <div
                          className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col ${checkSelect(participants[1].name.value, "Money")}`}
                        >
                          {
                            moneyLine?.options[1]?.status === 'Visible' && moneyLine?.options[1]?.price?.odds ?
                              <div className='w-full h-full flex flex-col justify-center items-center'
                                onClick={() => handelBet?.(participants[1].name.value, (decimal !== 'American' ? moneyLine?.options[1]?.price?.odds?.toString() : moneyLine?.options[1]?.price?.americanOdds?.toString()), "Money", moneyLine?.options[1]?.id)}
                              ><b className='text-[#E4E7F1] text-[18px]'>{(decimal !== 'American' ? moneyLine?.options[1]?.price?.odds.toFixed(2) : (Number(moneyLine?.options[1]?.price?.americanOdds) > 0 ? `+${moneyLine?.options[1]?.price?.americanOdds}` : moneyLine?.options[1]?.price?.americanOdds))}</b>
                              </div> :
                              <p className='text-[#E4E7F1]'><LockOutlined /></p>
                          }
                        </div>
                      ) : (
                        <div
                          className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col ${checkSelect(participants[1].name.value, "Money")}`}
                        >
                          <p className='text-[#E4E7F1]'><LockOutlined /></p>
                        </div>
                      )}
                    </div>
                  </div>
                </>
                :
                gameLine === 2 ?
                  //First half
                  <>
                    <div className="gameRatio pl-10">
                      <div className="homeRaito flex">
                        {FHspread?.status === 'Visible' ? (
                          <div
                            className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col ${checkSelect(participants[0].name.value, "First Half Spread")}`}
                          >
                            {
                              FHspread?.options[0]?.status === 'Visible' && FHspread?.options[0]?.attr ?
                                <div className='w-full h-full flex flex-col justify-center items-center'
                                  onClick={() => handelBet?.(participants[0].name.value, FHspread?.options[0]?.attr + " " + (decimal !== 'American' ? FHspread?.options[0]?.price?.odds : FHspread?.options[0]?.price?.americanOdds), "First Half Spread", FHspread?.options[0]?.id)}
                                >
                                  <p className='text-[#E4E7F1] text-[16px]'>{`${FHspread?.options[0]?.attr}`}</p>
                                  <p><b className='!text-[#E4E7F1] text-[18px]'>{`${(decimal !== 'American' ? FHspread?.options[0]?.price?.odds.toFixed(2) : (Number(FHspread?.options[0]?.price?.americanOdds) > 0 ? `+${FHspread?.options[0]?.price?.americanOdds}` : FHspread?.options[0]?.price?.americanOdds))}`}</b></p>
                                </div> :
                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                            }
                          </div>
                        ) : (
                          <div
                            className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col ${checkSelect(participants[0].name.value, "First Half Spread")}`}
                          >
                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                          </div>
                        )}

                        {FHtotals?.status === 'Visible' ? (
                          <div
                            className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col ${checkSelect(participants[0].name.value, "First Half Total")}`}
                          >
                            {
                              FHtotals?.options[0]?.status === 'Visible' && FHtotals?.options[0]?.name.value ?
                                <div className='w-full h-full flex flex-col justify-center items-center'
                                  onClick={() => handelBet?.(participants[0].name.value, FHtotals?.options[0]?.name.value + " " + (decimal !== 'American' ? FHtotals?.options[0]?.price.odds : FHtotals?.options[0]?.price.americanOdds), "First Half Total", FHtotals?.options[0]?.id)}
                                >
                                  <p className='text-[#E4E7F1] text-[16px]'>{`${FHtotals?.options[0]?.name.value}`}</p>
                                  <p><b className='text-[#E4E7F1] text-[18px]'>{`${(decimal !== 'American' ? FHtotals?.options[0]?.price.odds.toFixed(2) : (Number(FHtotals?.options[0]?.price.americanOdds) > 0 ? `+${FHtotals?.options[0]?.price.americanOdds}` : FHtotals?.options[0]?.price.americanOdds))}`}</b></p>
                                </div> :
                                <p className='text-[#E4E7F1]'><LockOutlined /></p>
                            }
                          </div>
                        ) : (
                          <div
                            className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] flex items-center flex-col ${checkSelect(participants[0].name.value, "First Half Total")}`}
                          >
                            <p className='text-[#E4E7F1]'><LockOutlined /></p>
                          </div>
                        )}

                        {FHmoneyLine?.status === 'Visible' ? (
                          <div
                            className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col ${checkSelect(participants[0].name.value, "First Half Money")}`}
                          >
                            {
                              FHmoneyLine?.options[0]?.status === 'Visible' && FHmoneyLine?.options[0]?.price?.odds ?
                                <div className='w-full h-full flex flex-col justify-center items-center'
                                  onClick={() => handelBet?.(participants[0].name.value, (decimal !== 'American' ? FHmoneyLine?.options[0]?.price.odds?.toString() : FHmoneyLine?.options[0]?.price.americanOdds?.toString()), "First Half Money", FHmoneyLine?.options[0]?.id)}
                                >
                                  <b className='text-[#E4E7F1] text-[18px]'>{(decimal !== 'American' ? FHmoneyLine?.options[0]?.price.odds.toFixed(2) : (Number(FHmoneyLine?.options[0]?.price.americanOdds) > 0 ? `+${FHmoneyLine?.options[0]?.price.americanOdds}` : FHmoneyLine?.options[0]?.price.americanOdds))}</b>
                                </div> :
                                <p className='text-[#E4E7F1]'><LockOutlined /></p>
                            }
                          </div>
                        ) : (
                          <div
                            className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col ${checkSelect(participants[0].name.value, "First Half Money")}`}
                          >
                            <p className='text-[#E4E7F1]'><LockOutlined /></p>
                          </div>
                        )}


                      </div>

                      <div className="awayRaito flex">
                        {FHspread?.status === 'Visible' ? (
                          <div
                            className={` my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col ${checkSelect(participants[1].name.value, "First Half Spread")}`}
                          >
                            {
                              FHspread?.options[1]?.status === 'Visible' && FHspread?.options[1]?.attr ?
                                <div className='w-full h-full flex flex-col justify-center items-center'
                                  onClick={() => handelBet?.(participants[1].name.value, FHspread?.options[1]?.attr + " " + (decimal !== 'American' ? FHspread?.options[1]?.price?.odds : FHspread?.options[1]?.price?.americanOdds), "First Half Spread", FHspread?.options[1]?.id)}
                                >
                                  <p className='text-[#E4E7F1] text-[16px]'>{`${FHspread?.options[1]?.attr}`}</p>
                                  <p><b className='text-[#E4E7F1] text-[18px]'>{`${(decimal !== 'American' ? FHspread?.options[1]?.price?.odds.toFixed(2) : (Number(FHspread?.options[1]?.price?.americanOdds) > 0 ? `+${FHspread?.options[1]?.price?.americanOdds}` : FHspread?.options[1]?.price?.americanOdds))}`}</b></p>
                                </div> :
                                <p className='text-[#E4E7F1]'><LockOutlined /></p>
                            }
                          </div>
                        ) : (
                          <div
                            className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col ${checkSelect(participants[1].name.value, "First Half Spread")}`}
                          >
                            <p className='text-[#E4E7F1]'><LockOutlined /></p>
                          </div>
                        )}

                        {FHtotals?.status === 'Visible' ? (
                          <div
                            className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col ${checkSelect(participants[1].name.value, "First Half Total")}`}
                          >
                            {
                              FHtotals?.options[1]?.status === 'Visible' && FHtotals?.options[1]?.name.value ?
                                <div className='w-full h-full flex flex-col justify-center items-center'
                                  onClick={() => handelBet?.(participants[1].name.value, FHtotals?.options[1]?.name.value + " " + (decimal !== 'American' ? FHtotals?.options[1]?.price.odds : FHtotals?.options[1]?.price.americanOdds), "First Half Total", FHtotals?.options[1]?.id)}
                                >
                                  <p className='text-[#E4E7F1] text-[16px]'>{`${FHtotals?.options[1]?.name.value}`}</p>
                                  <p><b className='text-[#E4E7F1] text-[18px]'>{`${(decimal !== 'American' ? FHtotals?.options[1]?.price.odds.toFixed(2) : (Number(FHtotals?.options[1]?.price.americanOdds) > 0 ? `+${FHtotals?.options[1]?.price.americanOdds}` : FHtotals?.options[1]?.price.americanOdds))}`}</b></p>
                                </div> :
                                <p className='text-[#E4E7F1]'><LockOutlined /></p>
                            }
                          </div>
                        ) : (
                          <div
                            className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col ${checkSelect(participants[1].name.value, "First Half Total")}`}
                          >
                            <p className='text-[#E4E7F1]'><LockOutlined /></p>
                          </div>
                        )}

                        {FHmoneyLine?.status === 'Visible' ? (
                          <div
                            className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col ${checkSelect(participants[1].name.value, "First Half Money")}`}
                          >
                            {
                              FHmoneyLine?.options[1]?.status === 'Visible' && FHmoneyLine?.options[1]?.price?.odds ?
                                <div className='w-full h-full flex flex-col justify-center items-center'
                                  onClick={() => handelBet?.(participants[1].name.value, (decimal !== 'American' ? FHmoneyLine?.options[1]?.price?.odds?.toString() : FHmoneyLine?.options[1]?.price?.americanOdds?.toString()), "First Half Money", FHmoneyLine?.options[1]?.id)}
                                ><b className='text-[#E4E7F1] text-[18px]'>{(decimal !== 'American' ? FHmoneyLine?.options[1]?.price?.odds.toFixed(2) : (Number(FHmoneyLine?.options[1]?.price?.americanOdds) > 0 ? `+${FHmoneyLine?.options[1]?.price?.americanOdds}` : FHmoneyLine?.options[1]?.price?.americanOdds))}</b>
                                </div> :
                                <p className='text-[#E4E7F1]'><LockOutlined /></p>
                            }
                          </div>
                        ) : (
                          <div
                            className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col ${checkSelect(participants[1].name.value, "First Half Money")}`}
                          >
                            <p className='text-[#E4E7F1]'><LockOutlined /></p>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className="gameRatio pl-10">
                      <div className="homeRaito flex">
                        {spread5Innings?.status === 'Visible' ? (
                          <div
                            className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col ${checkSelect(participants[0].name.value, "First 5 Innings Spread")}`}
                          >
                            {
                              spread5Innings?.options[0]?.status === 'Visible' && spread5Innings?.options[0]?.attr ?
                                <div className='w-full h-full flex flex-col justify-center items-center'
                                  onClick={() => handelBet?.(participants[0].name.value, spread5Innings?.options[0]?.attr + " " + (decimal !== 'American' ? spread5Innings?.options[0]?.price?.odds : spread5Innings?.options[0]?.price?.americanOdds), "First 5 Innings Spread", spread5Innings?.options[0]?.id)}
                                >
                                  <p className='text-[#E4E7F1] text-[16px]'>{`${spread5Innings?.options[0]?.attr}`}</p>
                                  <p><b className='!text-[#E4E7F1] text-[18px]'>{`${(decimal !== 'American' ? spread5Innings?.options[0]?.price?.odds.toFixed(2) : (Number(spread5Innings?.options[0]?.price?.americanOdds) > 0 ? `+${spread5Innings?.options[0]?.price?.americanOdds}` : spread5Innings?.options[0]?.price?.americanOdds))}`}</b></p>
                                </div> :
                                <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                            }
                          </div>
                        ) : (
                          <div
                            className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col ${checkSelect(participants[0].name.value, "First 5 Innings Spread")}`}
                          >
                            <p className='!text-[#E4E7F1]'><LockOutlined /></p>
                          </div>
                        )}

                        {totals5Innings?.status === 'Visible' ? (
                          <div
                            className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col ${checkSelect(participants[0].name.value, "First 5 Innings Total")}`}
                          >
                            {
                              totals5Innings?.options[0]?.status === 'Visible' && totals5Innings?.options[0]?.name.value ?
                                <div className='w-full h-full flex flex-col justify-center items-center'
                                  onClick={() => handelBet?.(participants[0].name.value, totals5Innings?.options[0]?.name.value + " " + (decimal !== 'American' ? totals5Innings?.options[0]?.price.odds : totals5Innings?.options[0]?.price.americanOdds), "First 5 Innings Total", totals5Innings?.options[0]?.id)}
                                >
                                  <p className='text-[#E4E7F1] text-[16px]'>{`${totals5Innings?.options[0]?.name.value}`}</p>
                                  <p><b className='text-[#E4E7F1] text-[18px]'>{`${(decimal !== 'American' ? totals5Innings?.options[0]?.price.odds.toFixed(2) : (Number(totals5Innings?.options[0]?.price.americanOdds) > 0 ? `+${totals5Innings?.options[0]?.price.americanOdds}` : totals5Innings?.options[0]?.price.americanOdds))}`}</b></p>
                                </div> :
                                <p className='text-[#E4E7F1]'><LockOutlined /></p>
                            }
                          </div>
                        ) : (
                          <div
                            className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] flex items-center flex-col ${checkSelect(participants[0].name.value, "First 5 Innings Total")}`}
                          >
                            <p className='text-[#E4E7F1]'><LockOutlined /></p>
                          </div>
                        )}

                        {moneyLine5Innings?.status === 'Visible' ? (
                          <div
                            className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col ${checkSelect(participants[0].name.value, "First 5 Innings Money")}`}
                          >
                            {
                              moneyLine5Innings?.options[0]?.status === 'Visible' && moneyLine5Innings?.options[0]?.price?.odds ?
                                <div className='w-full h-full flex flex-col justify-center items-center'
                                  onClick={() => handelBet?.(participants[0].name.value, (decimal !== 'American' ? moneyLine5Innings?.options[0]?.price.odds?.toString() : moneyLine5Innings?.options[0]?.price.americanOdds?.toString()), "First 5 Innings Money", moneyLine5Innings?.options[0]?.id)}
                                >
                                  <b className='text-[#E4E7F1] text-[18px]'>{(decimal !== 'American' ? moneyLine5Innings?.options[0]?.price.odds.toFixed(2) : (Number(moneyLine5Innings?.options[0]?.price.americanOdds) > 0 ? `+${moneyLine5Innings?.options[0]?.price.americanOdds}` : moneyLine5Innings?.options[0]?.price.americanOdds))}</b>
                                </div> :
                                <p className='text-[#E4E7F1]'><LockOutlined /></p>
                            }
                          </div>
                        ) : (
                          <div
                            className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col ${checkSelect(participants[0].name.value, "First 5 Innings Money")}`}
                          >
                            <p className='text-[#E4E7F1]'><LockOutlined /></p>
                          </div>
                        )}


                      </div>

                      <div className="awayRaito flex">
                        {spread5Innings?.status === 'Visible' ? (
                          <div
                            className={` my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col ${checkSelect(participants[1].name.value, "First 5 Innings Spread")}`}
                          >
                            {
                              spread5Innings?.options[1]?.status === 'Visible' && spread5Innings?.options[1]?.attr ?
                                <div className='w-full h-full flex flex-col justify-center items-center'
                                  onClick={() => handelBet?.(participants[1].name.value, spread5Innings?.options[1]?.attr + " " + (decimal !== 'American' ? spread5Innings?.options[1]?.price?.odds : spread5Innings?.options[1]?.price?.americanOdds), "First 5 Innings Spread", spread5Innings?.options[1]?.id)}
                                >
                                  <p className='text-[#E4E7F1] text-[16px]'>{`${spread5Innings?.options[1]?.attr}`}</p>
                                  <p><b className='text-[#E4E7F1] text-[18px]'>{`${(decimal !== 'American' ? spread5Innings?.options[1]?.price?.odds.toFixed(2) : (Number(spread5Innings?.options[1]?.price?.americanOdds) > 0 ? `+${spread5Innings?.options[1]?.price?.americanOdds}` : spread5Innings?.options[1]?.price?.americanOdds))}`}</b></p>
                                </div> :
                                <p className='text-[#E4E7F1]'><LockOutlined /></p>
                            }
                          </div>
                        ) : (
                          <div
                            className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col ${checkSelect(participants[1].name.value, "First 5 Innings Spread")}`}
                          >
                            <p className='text-[#E4E7F1]'><LockOutlined /></p>
                          </div>
                        )}

                        {totals5Innings?.status === 'Visible' ? (
                          <div
                            className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col ${checkSelect(participants[1].name.value, "First 5 Innings Total")}`}
                          >
                            {
                              totals5Innings?.options[1]?.status === 'Visible' && totals5Innings?.options[1]?.name.value ?
                                <div className='w-full h-full flex flex-col justify-center items-center'
                                  onClick={() => handelBet?.(participants[1].name.value, totals5Innings?.options[1]?.name.value + " " + (decimal !== 'American' ? totals5Innings?.options[1]?.price.odds : totals5Innings?.options[1]?.price.americanOdds), "First 5 Innings Total", totals5Innings?.options[1]?.id)}
                                >
                                  <p className='text-[#E4E7F1] text-[16px]'>{`${totals5Innings?.options[1]?.name.value}`}</p>
                                  <p><b className='text-[#E4E7F1] text-[18px]'>{`${(decimal !== 'American' ? totals5Innings?.options[1]?.price.odds.toFixed(2) : (Number(totals5Innings?.options[1]?.price.americanOdds) > 0 ? `+${totals5Innings?.options[1]?.price.americanOdds}` : totals5Innings?.options[1]?.price.americanOdds))}`}</b></p>
                                </div> :
                                <p className='text-[#E4E7F1]'><LockOutlined /></p>
                            }
                          </div>
                        ) : (
                          <div
                            className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col ${checkSelect(participants[1].name.value, "First 5 Innings Total")}`}
                          >
                            <p className='text-[#E4E7F1]'><LockOutlined /></p>
                          </div>
                        )}

                        {moneyLine5Innings?.status === 'Visible' ? (
                          <div
                            className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col ${checkSelect(participants[1].name.value, "First 5 Innings Money")}`}
                          >
                            {
                              moneyLine5Innings?.options[1]?.status === 'Visible' && moneyLine5Innings?.options[1]?.price?.odds ?
                                <div className='w-full h-full flex flex-col justify-center items-center'
                                  onClick={() => handelBet?.(participants[1].name.value, (decimal !== 'American' ? moneyLine5Innings?.options[1]?.price?.odds?.toString() : moneyLine5Innings?.options[1]?.price?.americanOdds?.toString()), "First 5 Innings Money", moneyLine5Innings?.options[1]?.id)}
                                ><b className='text-[#E4E7F1] text-[18px]'>{(decimal !== 'American' ? moneyLine5Innings?.options[1]?.price?.odds.toFixed(2) : (Number(moneyLine5Innings?.options[1]?.price?.americanOdds) > 0 ? `+${moneyLine5Innings?.options[1]?.price?.americanOdds}` : moneyLine5Innings?.options[1]?.price?.americanOdds))}</b>
                                </div> :
                                <p className='text-[#E4E7F1]'><LockOutlined /></p>
                            }
                          </div>
                        ) : (
                          <div
                            className={`my-2 mx-1 rounded bg-[#131620] border border-[#202531] !p-0 justify-center w-[96px] h-[56px] pl-2 flex items-center flex-col ${checkSelect(participants[1].name.value, "First 5 Innings Money")}`}
                          >
                            <p className='text-[#E4E7F1]'><LockOutlined /></p>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
              }



            </div>
          </>
        }
      </div >
    </div>

  )
}

export default GameDeTail_V2;