/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectorAlert, shiftAlert, TAlert } from '../store/slices/toastSlice';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { iconToastSuccess, iconToastWarning, iconToastDanger } from './imgExport';
const ToastGlobal = () => {
    const dispatch = useDispatch();
    const { toasts } = useSelector(selectorAlert);
    const [alert, setAlert] = useState<TAlert>({ message: "", icon: "success", duration: 2000 });
    const [isShow, setIsShow] = useState(false);
    useEffect(() => {
        if (!isShow) {
            showNextToast();
        }
    }, [toasts])

    const showNextToast = () => {
        if (toasts.length > 0) {
            setAlert(toasts[0]);
            setIsShow(true);
            dispatch(shiftAlert());
            switch (toasts[0].icon) {
                case 'success':
                    toast.success(<div>
                        <p className='text-left text-[18px]'>Success</p>
                        <p className='text-left text-[14px]'>{toasts[0].message}</p>
                    </div>
                        , { icon: <img src={iconToastSuccess} alt=""></img>, theme: "colored", className: "mt-5" });
                    break;
                case 'error':
                    toast.error(<div>
                        <p className='text-left text-[18px]'>Error</p>
                        <p className='text-left text-[14px]'>{toasts[0].message}</p>
                    </div>
                        , { icon: <img src={iconToastDanger} alt=""></img>, theme: "colored", className: "mt-5" });
                    break;
                case 'warn':
                    toast.warn(<div>
                        <p className='text-left text-[18px]'>Notice</p>
                        <p className='text-left text-[14px]'>{toasts[0].message}</p>
                    </div>
                        , { icon: <img src={iconToastWarning} alt=""></img>, className: "mt-5" });
                    break;
                case 'info':
                    toast.info(<div>
                        <p className='text-left text-[18px]'>Info</p>
                        <p className='text-left text-[14px]'>{toasts[0].message}</p>
                    </div>
                        , { icon: <img src={iconToastWarning} alt=""></img>, className: "mt-5" });
                    break;
                default:
                    toast(<div>
                        <p className='text-left text-[18px]'>Success</p>
                        <p className='text-left text-[14px]'>{toasts[0].message}</p>
                    </div>
                        , { icon: <img src={iconToastSuccess} alt=""></img>, className: "mt-5" });
                    break;
            }
            setTimeout(() => {
                setIsShow(false);
            }, toasts[0].duration)
        }
    }
    return (
        <ToastContainer position="bottom-left"
            className="!relative w-full" autoClose={alert.duration} theme="colored" />
    )
}

export default ToastGlobal;