import { useDispatch } from 'react-redux';
import { clearUser } from '../store/slices/userSlice';
import tokenService from '../services/token';
import useModalLogin from './useModalLogin';

const useResetData = () => {
    const dispatch = useDispatch();
    const { showModal } = useModalLogin()

    const resetLoginOut = () => {
        dispatch(clearUser())
        tokenService.removeToken()
    };

    const resetLoginExpired = (url: string) => {
        dispatch(clearUser())
        showModal(url)
        tokenService.removeToken()
    };
    const resetAllData = () => {
        dispatch(clearUser())
        showModal("/tabs/homepage")
        tokenService.removeToken()
        // languageUser.removelanguageUser()

    };

    return {
        resetLoginOut,
        resetLoginExpired,
        resetAllData
    };
};

export default useResetData;
