import { Modal } from "antd"
// import { useSelector } from "react-redux"
// import { selectorUser } from "../../store/slices/userSlice"
import { loadingConfirmbet, cancelConfirmBet } from "../imgExport";
import { useEffect, useState } from "react";

interface ModalBetsProps {
  status?: number,
  showModal: boolean,
  setShowModal: (value: boolean) => void
  handelConfirm: () => void
}

const ModalConfirmBet = ({ status, showModal, setShowModal, handelConfirm }: ModalBetsProps) => {
  // const { userId, presentMoney } = useSelector(selectorUser);
  const [countdown, setCountdown] = useState<number>(10)
  useEffect(() => {
    if (showModal) {
      var time = 10
      setInterval(() => {
        if (time > 0) {
          time -= 1
          setCountdown(time)
        }
      }, 1000);
    }
  }, [showModal])
  return (
    <Modal
      className='canPlayModal cancelConfirmBet flex justify-center'
      title={status === 1 ? <div className="flex justify-center items-center relative"><p className="absolute text-[#f4f4f5]">{countdown ? countdown : ""}</p><img className="rotating" src={loadingConfirmbet} alt="" /></div> : <img src={cancelConfirmBet} alt="" />}
      // title={status === 1 ? <Spin tip="Loading" size="large" className='size-9' /> : <img src={cancelConfirmBet} alt="" />}
      // open={true}
      open={showModal}
      onCancel={() => setShowModal(false)}
      footer={null}
      closable={false}
      maskClosable={false}
    >
      <p className="text-center !text-[18px] text-[#D4B962] !pb-1">{status === 1 ? "Bet Confirmation" : "Bet Canceled"}</p>
      <p className="!text-[14px] !pb-0">{status === 1 ? "Please wait, we are confirming your bet." : (status === 2 ? "The score has changed. Please place your bet again." : (status === 3 ? "The bet is locked. Please select another bet" : "The device has exceeded the allowable limit for this match."))}</p>
    </Modal>
  )
}
export default ModalConfirmBet