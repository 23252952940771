import { paserParams } from "../../until";
import axiosClient, { ResponseSuccess } from "../axiosClient";

export interface InfoGame {
  tournament: string
  key: any
  data: InfoGameCR[]
}

export interface InfoGameCR {
  game: string
  date: string
  teamFirst: InfoTeam
  teamSecond: InfoTeam
}

export interface InfoTeam {
  team: string
  img: string
  score: string
  spread: string
  total: string
  mline: string
}


//Get all data game live.
export interface InfoGameLive {
  id: string
  start_date: string
  home_team: string
  away_team: string
  is_live: boolean
  is_popular: boolean
  tournament: any
  status: string
  sport: string
  league: string
  odds: InfoOdd[]
}

export interface InfoOdd {
  id: string
  sports_book_name: string
  name: string
  price: number
  timestamp: number
  bet_points?: number | null
  is_main: boolean
  is_live: boolean
  market_name: string
  market: string
  home_rotation_number: any
  away_rotation_number: any
  deep_link_url: string
  player_id: any
  selection: string
  normalized_selection: string
  selection_line?: string | null
  selection_points?: number | null
}

//get-all-country-soccer
export interface InfoTournamentSoccer {
  key: number
  tournament: string
  hidden?: boolean
}

//Get game by tournament id
export interface InfoGameSoccer {
  country?: string
  game: string
  dateEvent: string
  time: string
  teamFirst: TeamFirst
  teamSecond: TeamSecond
  bettings: Bettings
}

export interface TeamFirst {
  key: number
  teamName: string
}

export interface TeamSecond {
  key: number
  teamName: string
}

export interface Bettings {
  threeWayBetting: ThreeWayBetting
  firstHalf1x2: FirstHalf1x2
  asianHandicap: AsianHandicap
  firstHaflAsiaHandicap: FirstHaflAsiaHandicap
  overUnder: OverUnder
  firstHalfOverUnder: FirstHalfOverUnder
  oddEven: OddEven
  totalGoals: OddTabLeft[]
  doubleChance: DoubleChance
  correctScore: CorrectScore
  correctScoreFirstHalf: CorrectScoreFirstHalf
  haflTimeFullTime: HaflTimeFullTime
  firstGoalLastGoal: FirstGoalLastGoal
}

export interface ThreeWayBetting {
  oddLeft: string
  oddDraw: string
  oddRight: string
}

export interface FirstHalf1x2 {
  oddLeft: string
  oddDraw: string
  oddRight: string
}

export interface AsianHandicap {
  teamFirst: TeamFirst2[]
  teamSecond: TeamSecond2[]
}

export interface TeamFirst2 {
  oddMoneyline: string
  oddRatio: string
}

export interface TeamSecond2 {
  oddMoneyline: string
  oddRatio: string
}

export interface FirstHaflAsiaHandicap {
  teamFirst: TeamFirst3[]
  teamSecond: TeamSecond3[]
}

export interface TeamFirst3 {
  oddMoneyline: string
  oddRatio: string
}

export interface TeamSecond3 {
  oddMoneyline: string
  oddRatio: string
}

export interface OverUnder {
  teamFirst: TeamFirst4[]
  teamSecond: TeamSecond4[]
}

export interface TeamFirst4 {
  oddLeft: string
  oddMoneyline: string
  oddRight: string
}

export interface TeamSecond4 {
  oddLeft: string
  oddMoneyline: string
  oddRight: string
}

export interface FirstHalfOverUnder {
  teamFirst: TeamFirst5[]
  teamSecond: TeamSecond5[]
}

export interface TeamFirst5 {
  oddLeft: string
  oddMoneyline: string
  oddRight: string
}

export interface TeamSecond5 {
  oddLeft: string
  oddMoneyline: string
  oddRight: string
}

export interface OddEven {
  teamFirst: OddTabLeft
  teamSecond: OddTabLeft
}

export interface DoubleChance {
  oddLeft: string
  oddDraw: string
  oddRight: string
}

export interface CorrectScore {
  oddTabLeft: OddTabLeft[]
  oddTabDraw: OddTabLeft[]
  oddTabRight: OddTabLeft[]
}

export interface OddTabLeft {
  oddLeft: string
  oddRight: string
}

export interface CorrectScoreFirstHalf {
  oddTabLeft: OddTabLeft[]
  oddTabDraw: OddTabLeft[]
  oddTabRight: OddTabLeft[]
}
export interface HaflTimeFullTime {
  oddTabLeft: OddTabLeft[]
  oddTabDraw: OddTabLeft[]
  oddTabRight: OddTabLeft[]
}
export interface FirstGoalLastGoal {
  oddTabLeft: OddTabLeft[]
  oddTabDraw: OddTabLeft[]
  oddTabRight: OddTabLeft[]
}

export interface InfoQuantityMoneyStraight {
  status: string
  totalStake: number
  minStraightMoney: number
  maxStraightMoney: number
}

export interface InfoQuantityMoneyParlay {
  status: string
  totalStake: number
  minParlayMoney: number
  maxParlayMoney: number
}

export interface InfoQuantityMoneyTeaser {
  status: string
  totalStake: number
  minTeaserMoney: number
  maxTeaserMoney: number
}

export interface InfoViewSetting {
  navigation: infoNavigation
  bettings: infoBettings
}

export interface infoNavigation {
  NBA: number
  NFL: number
  MLB: number
  NCAAF: number
  SOC: number
}

export interface infoBettings {
  Straight: number
  Parlay: number
  Teaser: number
  IfBet: number
  Reverse: number
}

export interface infoBanner {
  key: number
  title: string
  image: string
  content: string
  status: number
}

//Get list of games for the homepage
export const getListOfGamesForTheHomepage = async () => {
  const path = "api/v1/homepage"
  const params = {
  }
  const result: ResponseSuccess<InfoGame[]> = await axiosClient.get(
    path, { params: paserParams(params, undefined) }
  );
  return result;
}

//Get game live by tournament name (use the OpticOdds API)
export const getGameLiveByTournamentName = async (tournamentName: string, sportName?: string) => {
  const path = "api/v1/get-game-live"
  const params = {
    tournamentName, sportName
  }
  const result: ResponseSuccess<InfoGameLive[]> = await axiosClient.get(
    path, { params: paserParams(params, undefined) }
  );
  return result;
}

//Get all data game live.
export const getListAllDataGameLive = async () => {
  const path = "api/v1/get-all-game-live"
  const params = {
  }
  const result: ResponseSuccess<InfoGameLive[]> = await axiosClient.get(
    path, { params: paserParams(params, undefined) }
  );
  return result;
}

//get-all-tournament-soccer
export const getAllTournamentSoccer = async () => {
  const path = "api/v1/get-all-tournament-soccer"
  const params = {
  }
  const result: ResponseSuccess<InfoTournamentSoccer[]> = await axiosClient.get(
    path, { params: paserParams(params, undefined) }
  );
  return result;
}

//Get game by tournament id
export const getGameSoccerByTournamentId = async (tournamentId: number) => {
  const path = "api/v1/game-by-tournament-soccer"
  const params = {
    tournamentId
  }
  const result: ResponseSuccess<InfoGameSoccer[]> = await axiosClient.get(
    path, { params: paserParams(params, undefined) }
  );
  return result;
}


export const getTournamentByCountrySoccer = async (countryId: number) => {
  const path = "api/v1/get-tournament-by-country-soccer"
  const params = {
    countryId
  }
  const result: ResponseSuccess<InfoTournamentSoccer[]> = await axiosClient.get(
    path, { params: paserParams(params, undefined) }
  );
  return result;
}

export const checkQuantityMoneyStraight = async (totalStake: number) => {
  const path = "api/v1/check-quantity-money-straight"
  const params = {
    totalStake
  }
  const result: ResponseSuccess<InfoQuantityMoneyStraight> = await axiosClient.get(path,
    { params: paserParams(params, undefined) }
  );
  return result;
}

export const checkQuantityMoneyParlay = async (totalStake: number) => {
  const path = "api/v1/check-quantity-money-parlay"
  const params = {
    totalStake
  }
  const result: ResponseSuccess<InfoQuantityMoneyParlay> = await axiosClient.get(path,
    { params: paserParams(params, undefined) }
  );
  return result;
}

export const checkQuantityMoneyTeaser = async (totalStake: number) => {
  const path = "api/v1/check-quantity-money-teaser"
  const params = {
    totalStake
  }
  const result: ResponseSuccess<InfoQuantityMoneyTeaser> = await axiosClient.get(path,
    { params: paserParams(params, undefined) }
  );
  return result;
}

export const viewSettings = async () => {
  const path = "api/v1/view-settings"
  const params = {}
  const result: ResponseSuccess<InfoViewSetting> = await axiosClient.get(path,
    { params: paserParams(params, undefined) }
  );
  return result;
}

export const getBanners = async (sportId?: number) => {
  const path = "api/v1/get-banners"
  const params = { sportId }
  const result: ResponseSuccess<infoBanner[]> = await axiosClient.get(path,
    { params: paserParams(params, undefined) }
  );
  return result;
}

