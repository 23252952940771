import { Modal } from "antd"
import { useSelector } from "react-redux"
import { selectorUser } from "../../store/slices/userSlice"
import { formatPrice, unit } from "../../until"
import { CheckCircleOutlined } from "@mui/icons-material"

interface ModalBetsProps {
    showModal: boolean,
    setShowModal: (value: boolean) => void
    handelConfirm: () => void
}
const ContinueBetting = ({ showModal, setShowModal, handelConfirm }: ModalBetsProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { userId, presentMoney } = useSelector(selectorUser);
    return (

        <Modal
            className='canPlayModal withDrawModal continueBetting flex justify-center'
            title={<CheckCircleOutlined className='size-9' />}
            open={showModal}
            onOk={handelConfirm}
            onCancel={() => setShowModal(false)}
            okText="Withdraw"
            cancelText="Continue Betting"
        >
            <p className="text-center !text-[18px] text-[#52C41A] !pb-1">Bet Succesfull</p>
            <p className="!text-[14px] !pb-0">You have <b>{unit()}{formatPrice(presentMoney || 0)}</b>  left in your wallet.</p>
            <p className="!text-[14px] !pb-0">Do you want to continue betting or</p>
            <p className="!text-[14px] !pb-4">want to withdraw?</p>
        </Modal>
    )
}
export default ContinueBetting