import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface InfoUser {
    userId: string
    presentMoney: string
    authCode: string
    machineCode: string
}


const initialUserState: InfoUser = {
    userId: "0",
    presentMoney: "0",
    authCode: "",
    machineCode: "",
}

const userSlice = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers: {
        setUser: (state, action: PayloadAction<InfoUser>) => action.payload,
        setUserId: (state, action: PayloadAction<string>) => {
            state.userId = action.payload
        },
        setUserMoney: (state, action: PayloadAction<string>) => {
            state.presentMoney = action.payload
        },
        clearUser: (state) => initialUserState
    }
})

export const selectorUser = (state: { user: InfoUser }) => state.user;
export const selectorUserId = (state: { user: InfoUser }) => state.user.userId;

export const { setUser, setUserId, setUserMoney, clearUser } = userSlice.actions;
export default userSlice.reducer;