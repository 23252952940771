import { empty } from "../imgExport"

const EmptyGame: React.FC = () => {
  return (
    <div className="flex flex-col gap-3 items-center py-6">
      <img src={empty} alt="" />
      <span className='text-base fw-[400] text-[#C1C9E1]'>No data</span>
    </div>
  )
}
export default EmptyGame