import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectorLoading, setLoading } from '../store/slices/loadingSlice';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import useToast from './useToast';
import useDebounce from './useDebounce';
let timeout: any
const useLoading = () => {
    const dispatch = useDispatch();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { show, message } = useSelector(selectorLoading);

    const pushLoading = useDebounce((show: true | false, message: string = "") => {
        dispatch(setLoading({
            show,
            message
        }))
    }, 700)

    useEffect(() => {
        if (show) {
            timeout = setTimeout(() => {
                pushLoading(false)
            }, 30000)

        } else {
            clearTimeout(timeout)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);
    return pushLoading;
}

export default useLoading;