import { Modal } from "antd"
import { WarningOutlined } from '@ant-design/icons';
import { unitPoint } from "../../until";

interface ModalBetsProps {
    showModal: boolean,
    setShowModal: (value: boolean) => void
}
const NotEnoughModal = ({ showModal, setShowModal }: ModalBetsProps) => {

    return (
        <>
            <Modal
                className='canPlayModal cantPlayModal flex justify-center'
                title={<WarningOutlined className='size-9 pb-3' />}
                open={showModal}
                onCancel={() => setShowModal(false)}
                cancelText="Cancel"
            >
                <p className="text-[#D4B962] text-[#18px] !pb-1">Warning</p>
                <p>You don't have {unitPoint()} in your wallet</p>
            </Modal>
        </>
    )
}
export default NotEnoughModal