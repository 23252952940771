/* eslint-disable no-useless-concat */
/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from 'react';
import '../GameList/gameList.css'
import { ChooseBetSoccer } from '../../../../../api/betting/bettingApi';
// import { checkSelectSoccerNew_V2, getChangeClass_V3, getChangeColorSoccer_V3 } from '../../../../../until';
// import { LockOutlined, CaretRightOutlined } from '@ant-design/icons';
import { InfoGameDetailSoccer, Odd } from '../../../../../api/homePage/homePage_V2Api';
import * as homePage_V2Api from '../../../../../api/homePage/homePage_V2Api';
import * as bettingApi from '../../../../../api/betting/bettingApi';
interface SoccerTopHDPFProps {
    homeTeam: string
    awayTeam: string
    homeScore?: number
    awayScore?: number
    item: InfoGameDetailSoccer[]
    oddsOld?: Odd[]
    listChooseBet: ChooseBetSoccer[]
    listAddStrightBetsSlipSoccer?: bettingApi.InfoAddStrightSoccerTmp[]
    chooseBetTop?: (
        order: number, id: number, teamChoose: string, scoreChoose: string, option?: any
    ) => void
    checkUpdateStrightBetsSlip?: (
        id: number | string, scoreChoose: string, option: string, homeScore?: number, awayScore?: number
    ) => void
    updateOdds?: any[]
    dateFilterSoccer?: string
    listDateFilterSoccer?: homePage_V2Api.infoListDate[]
}
const SoccerTop1x2HSideBet: React.FC<SoccerTopHDPFProps> = ({
    homeTeam, awayTeam, item, listChooseBet, listAddStrightBetsSlipSoccer, oddsOld, homeScore, awayScore, dateFilterSoccer, listDateFilterSoccer,
    chooseBetTop, checkUpdateStrightBetsSlip,
    updateOdds
}) => {
    const odd = useRef<any[]>([item])
    const oddOld = useRef<any[]>([oddsOld])
    const updateArrayOdds = async () => {
        item.forEach(element => {
            updateOdds?.forEach(elementUpdateOdds => {
                if (element.id === elementUpdateOdds.id) {
                    let a = odd.current
                    setTimeout(() => {
                        const abc: any[] = odd.current.map(item => {
                            return item.id === elementUpdateOdds.id ? (elementUpdateOdds) : item
                        })
                        odd.current = abc
                        oddOld.current = a
                    }, 500);
                }
            });
        });
        setItemState(odd.current);
        setTimeout(() => {
            oddOld.current = odd.current
        }, 2000);
    }
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         if (updateOdds) {
    //             // setItemStateOld(oddOld.current)
    //             setItemState(odd.current);
    //         } else {
    //             // setItemStateOld(odd.current)
    //             setItemState(odd.current);
    //         }
    //     }, 3000);

    //     return () => clearInterval(interval);
    // }, []);

    useEffect(() => {
        updateArrayOdds()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateOdds])

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [itemState, setItemState] = useState<any[]>([item]);
    useEffect(() => {
        setItemState(item)
        odd.current = item
        oddOld.current = oddsOld ? oddsOld : item
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item])


    return (
        <>
            <div className='w-1/3 flex flex-col justify-between a1x2'>
                <div className='flex justify-between w-full h-[230px] items-center border-dashed border-b border-[#202531]'>
                    <div className='w-full flex flex-col hdp'>

                    </div >
                </div>
                <div className='flex justify-between w-full h-[230px] items-center mt-4 border-dashed border-b border-[#202531]'>
                    <div className='w-full flex flex-col hdp'>

                    </div >
                </div>
                <div className='flex justify-between w-full h-[230px] items-center mt-4 border-dashed border-[#202531]'>
                </div>
            </div >
        </>
    );
};

export default SoccerTop1x2HSideBet;