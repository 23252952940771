/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Flex } from 'antd';
// import { cart } from '../../../../../components/imgExport';
import { useDispatch, useSelector } from 'react-redux';
import { selectorUser, setUserMoney } from '../../../../../store/slices/userSlice';
import { formatPrice, unit, unitPoint } from '../../../../../until';
// import useResetData from '../../../../../hook/useResetData';
// import { useNavigate } from 'react-router-dom';
import LoadingModal from '../../../../../components/Modal/LoadingModal';
import WithDraw from '../../../../../components/Modal/WithDrawModal';
import { getPresentMoney, getRefundMoney, loadmoney, storeBillAcceptor } from '../../../../../api/billAcceptor/billAcceptorApi';
import useToast from '../../../../../hook/useToast';
import ModalWarning from '../../../../../components/Modal/ModalWarning';
import io from 'socket.io-client';
import ModalBillRefund from '../../../../../components/Modal/ModalBillRefund';
import { API_URL_BILL_ACCEPTOR } from '../../../../../env';
import useLoading from '../../../../../hook/useLoading';
import { TransactionWhite } from '../../../../../components/imgExport';
import useThrottle from '../../../../../hook/useThrottle';

const HeadRight: React.FC = () => {
  // const navigator = useNavigate()
  // const { resetLoginOut } = useResetData()
  const { userId, presentMoney, authCode } = useSelector(selectorUser);
  const pushToast = useToast();
  const dispatch = useDispatch();
  const pushLoading = useLoading();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [socket, setSocket] = useState<any>(null);

  const [money, setMoney] = useState("")
  const [billCode, setBillCode] = useState("")
  const [showLoading, setShowLoading] = useState(false)
  const [showWithDraw, setWithDraw] = useState(false)
  const [showModalWarning, setShowModalWarning] = useState(false)
  const [showModalBill, setShowModalBill] = useState(false)
  const [time, setTime] = useState(0)
  useEffect(() => {
    const socketInstance = io(API_URL_BILL_ACCEPTOR);
    setSocket(socketInstance);

    socketInstance.on('connect', () => {
      console.log('Connected to server get bill acceptor');
    });
    // socketInstance.emit("get-bill-acceptor", Number(authCode));
    socketInstance.on('bill-acceptor', async (data: any) => {
      if (data.authcode === authCode) {
        pushLoading(true)
        dispatch(setUserMoney(data.current_money))
        pushToast("You have successfully deposited money", "success")
        pushLoading(false)
      }
    });
    return () => {
      if (socketInstance) {
        socketInstance.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const getCurrentMoney = useThrottle(async () => {
    pushLoading(true);
    var timeTotal = 10
    const interval = setInterval(() => {
      if (timeTotal > 0) {
        timeTotal -= 1
        setTime(timeTotal)
      } else {
        clearInterval(interval)
      }
    }, 1000);
    const ketQua = await getPresentMoney();
    if (ketQua?.success) {
      dispatch(setUserMoney(ketQua.data.currentMoney))
      setMoney(ketQua.data.currentMoney)
    }
    setTimeout(() => {
      pushLoading(false);
    }, 1000)
  }, 10000)

  const handelConfirm = async () => {
    setWithDraw(false)
    setShowLoading(true);
    if (Number(presentMoney) > 0) {
      const getMoney = await getPresentMoney();
      if (getMoney.data) {
        await setMoney(getMoney.data.currentMoney)
        await setUserMoney(getMoney.data.currentMoney)
        const ketQua = await getRefundMoney(userId, getMoney.data.currentMoney);
        if (ketQua?.success) {
          // logOut()
          await setBillCode(ketQua.data.billCode)
          // await setMoney(presentMoney)
          await (setShowModalBill(true))
          handleResetMoney()
        } else {
          if (ketQua.type === "nem") {
            dispatch(setUserMoney(ketQua.data.presentMoney))
            setShowModalWarning(true)
          }
          pushToast(ketQua?.message, "warn");
        }
      } else {
        pushToast("Can't connect to server.", "warn");
      }

    }
    setShowLoading(false);
  }

  const handleResetMoney = () => {
    dispatch(setUserMoney("0"))
  };

  const handleCloseModalBill = () => {
    setShowModalBill(false)
    // dispatch(setUserMoney("0"))
  };

  useEffect(() => {
    getCurrentMoney()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])





  const socketRef = useRef<WebSocket | null>(null);
  const [messages, setMessages] = useState<string[]>([]);

  let input_Message = "";
  useEffect(() => {
    socketRef.current = new WebSocket('ws://localhost:5056');
    socketRef.current.onopen = () => {
    };
    socketRef.current.onmessage = async (event) => {
      if (event.data instanceof Blob) {
        const text = await event.data.text();
        handleEvent(JSON.parse(text));
        setMessages((prevMessages) => [...prevMessages, text]);
      } else {
        setMessages((prevMessages) => [...prevMessages, event.data]);
      }
    };
    socketRef.current.onclose = () => {
    };
    socketRef.current.onerror = (error) => {
    };
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, []);

  // Hàm gửi tin nhắn
  const sendMessage = () => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(input_Message);
      input_Message = ''; // Reset input
    } else {
      console.log('WebSocket is not open');
    }
  };
  const handleEvent = async (message: { eventName: string; params?: any }) => {
    if (message.eventName === "update_money") {
      const { billAcceptorId, bankNote, timestamp } = message.params || {};
      const ketQua = await loadmoney(bankNote)
      if (ketQua.success) {
        pushToast(`You have successfully deposited ${unitPoint()}`, "success")
        dispatch(setUserMoney(ketQua.data.presentMoney ? ketQua.data.presentMoney : "0"))
        setMoney(ketQua.data.presentMoney ? ketQua.data.presentMoney : "0")
        await setMoney(ketQua.data.presentMoney ? ketQua.data.presentMoney : "0")
        await setUserMoney(ketQua.data.presentMoney ? ketQua.data.presentMoney : "0")
      } else {
        pushToast("Some thing wrong. Please try later", "warn")
      }
    }
  };

  return (
    <Flex gap="small" wrap="wrap" style={{ alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>

      <LoadingModal
        showModal={showLoading}
        setShowModal={setShowLoading}
      />

      <WithDraw
        showModal={showWithDraw}
        setShowModal={setWithDraw}
        handelConfirm={handelConfirm}
      />

      <ModalWarning
        showModal={showModalWarning}
        setShowModal={setShowModalWarning}
      />

      <ModalBillRefund
        billCode={billCode}
        showModalBill={showModalBill}
        totalAmount={+money}
        setShowModalBill={handleCloseModalBill}
      />
      <Button
        type="primary"
        style={{
          paddingLeft: '6px',
          paddingRight: '6px',
          color: '#FFC701',
          height: '38px',
          background: 'transparent',
          // border: '1px solid rgba(255, 206, 112, 0.4), 1px solid rgba(188, 102, 55, 0.4), 1px solid rgba(0, 0, 0, 0.4)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: 'unset'
        }}
        onClick={() => (presentMoney && Number(presentMoney) >= 0 ? setWithDraw(true) : "")}
      >
        {unit()}{formatPrice(presentMoney || 0)}
      </Button>
      <div className='pl-1 cursor-pointer h-10 text-white flex items-center gap-2' onClick={() => getCurrentMoney()}>
        {time && time > 0 ? time : <img src={TransactionWhite} alt="Transaction" className='h-[20px] aspect-square' />}
        <span className='text-sm fw-[400] uppercase text-white h-fit'>Reload</span>
      </div>
    </Flex>
  );
}

export default HeadRight;

