import { paserParams } from "../../until";
import axiosClient, { ResponseSuccess, ResponseSuccessStores, ResponseSuccessType } from "../axiosClient";
import { InfoUser } from "../billAcceptor/billAcceptorApi";

export interface InfoGame {
  tournament: string
  key: any
  data: InfoGameCR[]
}

export interface InfoGameCR {
  game: string
  date: string
  teamFirst: InfoTeam
  teamSecond: InfoTeam
}

export interface InfoTeam {
  team: string
  img: string
  score: string
  spread: string
  total: string
  mline: string
}

export interface ChooseBet {
  tournament: string,
  game: string,
  teamChoose: string,
  scoreChoose: string,
  lineBetting: "Spread" | "Money" | "Total" | "First Half Spread" | "First Half Money" | "First Half Total" | "First 5 Innings Spread" | "First 5 Innings Total" | "First 5 Innings Money" | string
  dateEvent: string
  dateEventServer?: string
  live?: boolean
  id?: number | string
  idGame?: number | string
  optionId?: number | string
  option?: number | string
}

export interface InfoStrightBetsSlip {
  tournament: string
  game: string
  teamChoose: string
  scoreChoose: string
  lineBetting: string
  dateEvent: string
}


export interface InfoAddParlayBetsSlip {
  totalOdds: number
  t?: number
  data: InfoParlayBetsSlip[]
}

export interface InfoParlayBetsSlip {
  tournament: string
  game: string
  teamChoose: string
  scoreChoose: string
  lineBetting: string
  dateEvent: string
  odds: number
}

export interface InfoParlayBetsSlipTMP {
  tournament: string
  game: string
  teamChoose: string
  scoreChoose: string
  lineBetting: string
  dateEvent: string
  dateEventServer?: string
  odds: number
  isCheck: boolean
  isWarning: boolean
  color?: string
  live?: boolean
  stage?: number
  change?: boolean
  id?: number | string
  homeLiveScore?: number
  awayLiveScore?: number
}
export interface InfoTeaserBetsSlipTMP {
  tournament: string
  game: string
  teamChoose: string
  scoreChoose: string
  lineBetting: string
  dateEvent: string
  odds: number
  isCheck: boolean
  isWarning: boolean
  live?: boolean
  change?: boolean
}

export interface InfoStrightBetsSlipTMP {
  tournament: string
  game: string
  teamChoose: string
  scoreChoose: string
  lineBetting: string
  dateEvent: string
  dateEventServer?: string
  amount: number
  win: string
  isCheck: boolean
  color?: string
  live?: boolean
  stage?: number
  change?: boolean
  id?: number | string
  optionId?: number | string
  homeLiveScore?: number
  awayLiveScore?: number
}
export interface InfoBets {
  tournament: string
  game: string
  teamChoose: string
  scoreChoose: string
  lineBetting: string
  dateEvent: string
  amount: string
  win: string
  live?: boolean
}
export interface InfoBetsParlay {
  tournament: string
  game: string
  teamChoose: string
  scoreChoose: string
  lineBetting: string
  dateEvent: string
  odds: number
  live?: boolean
}

export interface InfoBill {
  insertId: number
  billCode?: string
  tournament: string
  game: string
  teamChoose: string
  dateEvent: string
  scoreChoose: string
  lineBetting: string
  amount: string
  win: string
  date: string
  live?: boolean
  homeLiveScore?: string
  awayLiveScore?: string
}
export interface InfoBillParlay {
  data: InfoBill[]
  totalOdds: number
  totalStake: number
}

//Add teaser betting in Bet Slip tag.
export interface InfoAddTeaserBetsSlip {
  totalOdds: number
  totalStake: number
  potentialPayout: string
  data: InfoBetTeaser[]
}
export interface InfoBetTeaser {
  tournament: string
  game: string
  teamChoose: string
  scoreChoose: string
  lineBetting: string
  dateEvent: string
  point: number
  newLine: number
  live?: boolean
}

export interface InfoBetTeaserTMP {
  tournament: string
  game: string
  teamChoose: string
  scoreChoose: string
  lineBetting: string
  dateEvent: string
  dateEventServer?: string
  point: number
  newLine: number
  isCheck: boolean
  isWarning: boolean
  live?: boolean
  stage?: number
  id?: number | string
}

export interface InfoBillTeaser {
  data: InfoBillTeaserC[]
  point: number
  totalOdds: number
  totalStake: number
}

export interface InfoBillTeaserC {
  insertId: number
  sportName: string
  tournament: string
  game: string
  teamChoose: string
  dateEvent: string
  scoreChoose: string
  lineBetting: string
  date: string
  newLine: number
}

// Soccer
//Add straight bet soccer in betslip.

export interface ChooseBetSoccer {
  country: string
  tournament: string
  game: string
  teamChoose: string
  scoreChoose: string
  lineBetting: lineBettingSoccer
  dateEvent: string
  id?: number | string
  order?: number
  orderId?: number | string
  idGame?: number | string
  option?: string
}

export interface InfoAddStrightSoccer {
  totalStake: number
  potentialPayout: number
  country: string
  tournament: string
  game: string
  teamChoose: string
  scoreChoose: string
  lineBetting: string
  dateEvent: string
}

export interface InfoBetStrightSoccer {
  totalStake: number
  potentialPayout: number
  country: string
  tournament: string
  game: string
  teamChoose: string
  scoreChoose: string
  lineBetting: string
  dateEvent: string
  amount: number
  win: number
  live?: boolean
  stage?: string
  id?: string | number | undefined
  option?: any
}
export interface InfoAddStrightSoccerTmpCheckUpdate {
  orderId: number | string
}
export interface InfoAddStrightSoccerTmp {
  totalStake: number
  potentialPayout: number
  country: string
  id?: number | string
  order?: number | string
  orderId?: number | string
  option?: any
  tournament: string
  game: string
  teamChoose: string
  scoreChoose: string
  lineBetting: string
  dateEvent: string
  amount: number
  win: number
  isCheck: boolean
  live?: boolean
  idGame?: number | string
  change?: boolean
  homeLiveScore?: number
  awayLiveScore?: number
}

export interface InfoBillStrightSoccer {
  insertId: number
  sportName: string
  country: string
  tournament: string
  game: string
  teamChoose: string
  dateEvent: string
  scoreChoose: string
  lineBetting: string
  amount: number
  win: number
  date: string
  billCode?: string
  live?: boolean
  stage?: string
  homeLiveScore?: number
  awayLiveScore?: number
}

//Pa
export interface InfoAddParlaySoccer {
  t: number
  totalStake: number
  totalOdds: number
  potentinalPayout: number
  data: InfoParlaySoccer[]
}

export interface InfoParlaySoccer {
  country: string
  tournament: string
  game: string
  teamChoose: string
  scoreChoose: string
  lineBetting: string
  dateEvent: string
  stage?: string
}

export interface InfoParlaySoccerTmp {
  country: string
  tournament: string
  game: string
  teamChoose: string
  scoreChoose: string
  lineBetting: string
  dateEvent: string
  isCheck: boolean
  isWarning: boolean
  id?: number
  option?: number | string
  orderId?: number | string
  idGame?: number | string
  order?: number
  stage?: string
  change?: boolean
}


export interface InfoBillParlaySoccer {
  totalOdds: number
  totalStake: number
  data: InfoBillParlaySoccerC[]
}

export interface InfoBillParlaySoccerC {
  insertId: number
  sportName: string
  tournament: string
  game: string
  teamChoose: string
  dateEvent: string
  scoreChoose: string
  lineBetting: string
  date: string
}

export type lineBettingSoccer = "Fulltime A 1x2" | "Fulltime HDP" | "Fulltime OU" | "Fulltime 1x2" | "OddEven" | "FH_Handicap" | "First Half HDP" | "First Half OU" | "First Half 1x2" | "Total Goal" | "Double Chance" | "Correct Score" | "First Half Correct Score" | "Half Time/Full Time" | "First Goal/Last Goal" | "Fulltime Total Corners OU" | "Fulltime Total Corners HDP" | "First Half Total Corners HDP" | "First Half Total Corners OU"
//Add Stright Bets slip.
export const addStrightBetsSlip = async (tournament: string, game: string, teamChoose: string, scoreChoose: string, lineBetting: string, dateEvent: string) => {
  const path = "api/v1/addstraightbet"
  const params = {
    tournament, game, teamChoose, scoreChoose, lineBetting, dateEvent
  }
  const result: ResponseSuccess<InfoStrightBetsSlip> = await axiosClient.get(
    path, { params: paserParams(params, undefined) }
  );
  return result;
}

export const addStoreStrightBetsSlip = async (machineId: string, bettings: InfoBets[], ip?: string, location?: string, oddType?: string) => {
  var bettingsTmp = JSON.stringify(bettings)
  const path = "api/v1/storestraightbet"
  const params = {
    machineId, bettings: bettingsTmp, ip, location, oddType
  }
  const result: ResponseSuccessStores<InfoBill[]> = await axiosClient.post(path,
    paserParams(params, undefined)
  );
  return result;
};

//Add parlay betting in Bet Slip tag.
export const addParlayBetsSlip = async (listParlayBetsSlip: ChooseBet[], oddType?: string) => {
  var bettingsTmp = JSON.stringify(listParlayBetsSlip)
  const path = "api/v1/addparlaybet"
  const params = {
    bettings: bettingsTmp,
    oddType
  }
  const result: ResponseSuccess<InfoAddParlayBetsSlip> = await axiosClient.get(
    path, { params: paserParams(params, undefined) }
  );
  return result;
}

export const addStoreParlayBetsSlip = async (machineId: string, totalOdds: number, totalStake: number, bettings: InfoBetsParlay[], ip?: string, location?: string) => {
  var bettingsTmp = JSON.stringify(bettings)
  const path = "api/v1/storeparlaybet"
  const params = {
    machineId, totalOdds, totalStake, bettings: bettingsTmp, ip, location
  }
  const result: ResponseSuccessStores<InfoBillParlay> = await axiosClient.post(path,
    paserParams(params, undefined)
  );
  return result;
};

//Add teaser betting in Bet Slip tag.
export const addTeaserBetsSlip = async (point: string, listTeaserBetsSlip: ChooseBet[], totalStake?: string, oddType?: string) => {
  var bettingsTmp = JSON.stringify(listTeaserBetsSlip)
  const path = "api/v1/addteaserbet"
  const params = {
    bettings: bettingsTmp, point, totalStake, oddType
  }
  const result: ResponseSuccess<InfoAddTeaserBetsSlip> = await axiosClient.get(
    path, { params: paserParams(params, undefined) }
  );
  return result;
}

//Update infomation betting when point is change.
export const updatePointTeaserBet = async (point: string, listTeaserBetsSlip: ChooseBet[], totalStake?: string) => {
  var bettingsTmp = JSON.stringify(listTeaserBetsSlip)
  const path = "api/v1/changepointteaserbet"
  const params = {
    bettings: bettingsTmp, point, totalStake
  }
  const result: ResponseSuccess<InfoAddTeaserBetsSlip> = await axiosClient.get(
    path, { params: paserParams(params, undefined) }
  );
  return result;
}

//Store Teaser bets slip.
export const addStoreTeaserBetsSlip = async (machineId: string, point: number, totalOdds: number, totalStake: number, bettings: InfoBetTeaser[], ip?: string, location?: string) => {
  var bettingsTmp = JSON.stringify(bettings)
  const path = "api/v1/storeteaserbet"
  const params = {
    machineId, point, totalOdds, totalStake, bettings: bettingsTmp, ip, location
  }
  const result: ResponseSuccessStores<InfoBillTeaser> = await axiosClient.post(path,
    paserParams(params, undefined)
  );
  return result;
};

// Soccer
//Add straight bet soccer in betslip.
export const addStraightBetSoccerBetsSlip = async (
  country: string, tournament: string, game: string, teamChoose: string, scoreChoose: string, lineBetting: lineBettingSoccer, dateEvent: string, totalStake?: number
) => {
  const path = "api/v1/add-straight-bet-soccer"
  const params = {
    country, tournament, game, teamChoose, scoreChoose, lineBetting, dateEvent, totalStake
  }
  const result: ResponseSuccess<InfoAddStrightSoccer> = await axiosClient.get(
    path, { params: paserParams(params, undefined) }
  );
  return result;
}


//Store Straight bets slip Soccer.
export const addStoreStraightSoccerBetsSlip = async (machineId: string, bettings: InfoBetStrightSoccer[], ip?: string, location?: string) => {
  var bettingsTmp = JSON.stringify(bettings)
  const path = "/api/v1/store-straight-bet-soccer"
  const params = {
    machineId, bettings: bettingsTmp, ip, location
  }
  const result: ResponseSuccessStores<InfoBillStrightSoccer[]> = await axiosClient.post(path,
    paserParams(params, undefined)
  );
  return result;
};

//Add parlay bet to betslip soccer
export const addParlayBetSoccer = async (listTeaserBetsSlip: ChooseBetSoccer[], totalStake?: string) => {
  var bettingsTmp = JSON.stringify(listTeaserBetsSlip)
  const path = "api/v1/add-parlay-bet-soccer"
  const params = {
    bettings: bettingsTmp, totalStake
  }
  const result: ResponseSuccess<InfoAddParlaySoccer> = await axiosClient.get(
    path, { params: paserParams(params, undefined) }
  );
  return result;
}



//Store parlay bet to DB
export const addStoreParlaySoccer = async (machineId: string, totalOdds: number, totalStake: number, bettings: InfoParlaySoccer[], ip?: string, location?: string) => {
  var bettingsTmp = JSON.stringify(bettings)
  const path = "api/v1/store-parlay-bet-soccer"
  const params = {
    machineId, bettings: bettingsTmp, totalOdds, totalStake, ip, location
  }
  const result: ResponseSuccessStores<InfoBillParlaySoccer> = await axiosClient.post(path,
    paserParams(params, undefined)
  );
  return result;
};

export const checkMoneyBetting = async (money: string, totalStake: number, bettings: InfoBets[] | InfoBetsParlay[] | InfoBetTeaser[] | InfoBetStrightSoccer[] | InfoParlaySoccer[], betType?: string) => {
  var bettingsTmp = JSON.stringify(bettings)
  const path = "api/v1/checkmoneybetting"
  const params = {
    bettings: bettingsTmp, money, totalStake, betType
  }
  const result: ResponseSuccessType<InfoUser> = await axiosClient.post(path,
    paserParams(params, undefined)
  );
  return result;
};

export interface InfoSettingsBet {
  quantity: Quantity
  money: Money
}

export interface Quantity {
  straight: number
  parlay: number
  teaser: number
  ifBet: number
  reverse: number
}

export interface Money {
  straight: Straight
  parlay: Parlay
  teaser: Teaser
  ifBet: IfBet
  reverse: Reverse
}

export interface Straight {
  min: number
  max: number
}

export interface Parlay {
  min: number
  max: number
}

export interface Teaser {
  min: number
  max: number
}

export interface IfBet {
  min: number
  max: number
}

export interface Reverse {
  min: number
  max: number
}

export const getSettingsBet = async () => {
  const path = "api/v1/get-settings-bet"
  const params = {}
  const result: ResponseSuccessType<InfoSettingsBet> = await axiosClient.get(
    path, { params: paserParams(params, undefined) }
  );
  return result;
};


