import axios from "axios";
import qs from 'qs';
import * as ENV from "../env";

const authuClient = axios.create({
    baseURL: ENV.API_URL,
    headers: {
        'content-type': 'multipart/form-data',
    },
    paramsSerializer: {
        serialize: params => {
            return qs.stringify(params, { arrayFormat: 'repeat', allowDots: true });
        }
    }

});

authuClient.interceptors.request.use(async (config) => {

    return config;
}, (error) => {
    return Promise.reject(error);
});

authuClient.interceptors.response.use((response) => {
    if (response && response?.data) {
        return response.data;
    }
    return response;
}, (error) => {
    // console.error(error.response.data);
    if (error.response && error.response.data && error.response.data.error &&
        (error.response.data.session === false || error.response.data.session === "false")) {
        alert("Đã xảy ra sự cố, sẽ tự động đăng xuất.");
        window.location.href = "/";
    } else if (error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
        // toastMessage(error.response.data.error.message, 1);
        return error.response?.data
    } else if (error.response && error.response.status === 500) {
        return error?.response?.data;
    } else {
        return error?.response?.data;
    }
});

export default authuClient;