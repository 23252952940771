import { Button } from "antd";
import { CloseSquareOutlined, CaretRightOutlined } from '@ant-design/icons';


interface TotalStakeProps {
    showTotalStake: boolean,
    handleClick: (total: number) => void
    handleNumberClick: (total: number) => void
    handleDeleteClick: (index: number) => void
    handleSuccess: () => void
}
const CaculateComponent = ({ showTotalStake, handleClick, handleNumberClick, handleDeleteClick, handleSuccess }: TotalStakeProps) => {
    return (
        <div className={` ${showTotalStake ? '' : 'hidden'}`}>
            <div className='grid grid-cols-4 gap-1'>
                <Button className="!h-[40px] !bg-[#20273D] !text-white !border-none button-font-bold relative" onClick={() => handleClick(5)} >+5
                    <CaretRightOutlined className="absolute bottom-[0px] right-[0px] rotate-45" />
                </Button>
                <Button className="!h-[40px] !bg-[#20273D] !text-white !border-none button-font-bold relative" onClick={() => handleClick(10)} >+10
                    <CaretRightOutlined className="absolute bottom-[0px] right-[0px] rotate-45" />
                </Button>
                <Button className="!h-[40px] !bg-[#20273D] !text-white !border-none button-font-bold relative" onClick={() => handleClick(15)} >+15
                    <CaretRightOutlined className="absolute bottom-[0px] right-[0px] rotate-45" />
                </Button>
                <Button className="!h-[40px] !bg-[#20273D] !text-white !border-none button-font-bold relative" onClick={() => handleClick(20)} >+20
                    <CaretRightOutlined className="absolute bottom-[0px] right-[0px] rotate-45" />
                </Button>
                <Button className="!h-[40px] !bg-[#20273D] !text-white !border-none button-font-bold relative" onClick={() => handleClick(30)} >+30
                    <CaretRightOutlined className="absolute bottom-[0px] right-[0px] rotate-45" />
                </Button>
                <Button className="!h-[40px] !bg-[#20273D] !text-white !border-none button-font-bold relative" onClick={() => handleClick(50)} >+50
                    <CaretRightOutlined className="absolute bottom-[0px] right-[0px] rotate-45" />
                </Button>
                <Button className="!h-[40px] !bg-[#20273D] !text-white !border-none button-font-bold relative" onClick={() => handleClick(100)} >+100
                    <CaretRightOutlined className="absolute bottom-[0px] right-[0px] rotate-45" />
                </Button>
                <Button className="!h-[40px] !bg-[#20273D] !text-white !border-none button-font-bold relative" onClick={() => handleClick(200)} >+200
                    <CaretRightOutlined className="absolute bottom-[0px] right-[0px] rotate-45" />
                </Button>
            </div>
            {/* <div className='flex flex-wrap keyBoard py-3'> */}
            <div className='grid grid-cols-4 gap-1 py-2'>
                <Button className="!h-[66px] !bg-[#20273D] !text-white !border-none button-font-bold" onClick={() => handleNumberClick(1)}>1</Button>
                <Button className="!h-[66px] !bg-[#20273D] !text-white !border-none button-font-bold" onClick={() => handleNumberClick(2)}>2</Button>
                <Button className="!h-[66px] !bg-[#20273D] !text-white !border-none button-font-bold" onClick={() => handleNumberClick(3)}>3</Button>
                <Button className="!h-[66px] !bg-[#20273D] !text-white !border-none button-font-bold" onClick={() => handleNumberClick(4)}>4</Button>
                <Button className="!h-[66px] !bg-[#20273D] !text-white !border-none button-font-bold" onClick={() => handleNumberClick(5)}>5</Button>
                <Button className="!h-[66px] !bg-[#20273D] !text-white !border-none button-font-bold" onClick={() => handleNumberClick(6)}>6</Button>
                <Button className="!h-[66px] !bg-[#20273D] !text-white !border-none button-font-bold" onClick={() => handleNumberClick(7)}>7</Button>
                <Button className="!h-[66px] !bg-[#20273D] !text-white !border-none button-font-bold" onClick={() => handleNumberClick(8)}>8</Button>
                <Button className="!h-[66px] !bg-[#20273D] !text-white !border-none button-font-bold" onClick={() => handleNumberClick(9)}>9</Button>
                <Button className="!h-[66px] !bg-[#20273D] !text-white !border-none button-font-bold" onClick={() => handleNumberClick(0)}>0</Button>
                <Button className="!h-[66px] !bg-[#20273D] !text-white !border-none button-font-bold" onClick={() => handleDeleteClick(0)}><CloseSquareOutlined /></Button>
                <Button className="!h-[66px] !bg-[#D4B962] !text-[#131620] !border-none button-font-bold" onClick={handleSuccess}>OK</Button>
                {/* <Button className="!h-[66px] rounded-md !bg-[#20273D] !text-white !bg-opacity-20 !color-[black] !button-font-bold " type="primary" onClick={handleSuccess}>OK</Button> */}
            </div>
            {/* </div> */}
        </div>
    )
}
export default CaculateComponent