import { paserParams } from "../../until";
import axiosClient, { ResponseSuccess } from "../axiosClient";
import { InfoGame } from "../homePage/homePageApi";


export interface InfoTournament {
    key: string
    label: string
    icon: string
    data?: InfoTournamentChildren[]
}

export interface InfoTournamentChildren {
    key: string
    label: string
    icon: string
    amount: number
}


export interface MenuInfo {
    key: string;
    keyPath: string[];
    /** @deprecated This will not support in future. You should avoid to use this */
    item: React.ReactInstance;
    domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>;
}


export interface InfoGameByCountryAndGame {
    key: number
    tournament: string
    data?: InfoGameByCountryAndGameChildren[]
}

export interface InfoGameByCountryAndGameChildren {
    team: string
    date: string
    spread: string
    total: string
    mline: string
}

export interface SelectInfo extends MenuInfo {
    selectedKeys: string[];
}
type SelectEventHandler = (info: SelectInfo) => void;

export const getTournament = async () => {
    const path = "api/v1/sports"
    const params = {
    }
    const result: ResponseSuccess<InfoTournament[]> = await axiosClient.get(
        path, { params: paserParams(params, undefined) }
    );
    return result;
}

export const getListOfGameByCountryAndGame = async (sportId: number, countryId: number) => {
    const path = "api/v1/game"
    const params = {
        sportId, countryId
    }
    const result: ResponseSuccess<InfoGame[]> = await axiosClient.get(
        path, { params: paserParams(params, undefined) }
    );
    return result;
}

//Get list of games by tournament id.
export const getListGameByTournament = async (tournamentId: number) => {
    const path = "api/v1/gamebytournament"
    const params = {
        tournamentId
    }
    const result: ResponseSuccess<InfoGame[]> = await axiosClient.get(
        path, { params: paserParams(params, undefined) }
    );
    return result;
}
