/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-redeclare */
import React from 'react';
import { Collapse } from 'antd';
import type { CollapseProps } from 'antd';
import { TotalPoints, WinningMargin } from '../../services/data';

interface ThreeColItems {
    name: string;
    detailTotal1: string;
    detailTotal2: string;
    detailTotal3: string;
}

interface OneColItems {
    Total1: string;
    detailTotal1: string;
}
const ThreeColItems = ({ detailTotal1, detailTotal2, detailTotal3, name }: ThreeColItems) => {
    return (
        <div className='flex items-center '>
            <div className='w-1/2'>
                <p className='text-left font-bold'>{name}</p>
            </div>
            <div className='w-1/2 flex gap-1'>
                <div className='w-full flex items-center justify-center h-[38px] border-[1px] border-gray-300 rounded-lg '>
                    <p className='font-bold'>{detailTotal1}</p>
                </div>
                <div className='w-full flex items-center justify-center h-[38px] border-[1px] border-gray-300 rounded-lg '>
                    <p className='font-bold'>{detailTotal2}</p>
                </div>
                <div className='w-full flex items-center justify-center h-[38px] border-[1px] border-gray-300 rounded-lg '>
                    <p className='font-bold'>{detailTotal3}</p>
                </div>
            </div>
        </div>
    )
}

const OneColItems = ({ Total1, detailTotal1 }: OneColItems) => {
    return (
        <div className='flex items-center '>
            <div className='w-full flex gap-1'>
                <div className='w-1/2'>
                    <p className='text-left font-bold'>{Total1}</p>
                </div>
                <div className='w-1/2 border-[1px] border-gray-300 rounded-lg '>
                    <p className='font-bold'>{detailTotal1}</p>
                </div>
            </div>
        </div>
    )
}

const FutureTabs: React.FC = () => {

    const outringtWinner: CollapseProps['items'] = [
        {
            key: '1',
            label: 'Outringt Winner',
            children:
                <div>
                    <div className='w-full flex justify-end'>
                        <div className='w-1/2  h-10 flex gap-1'>
                            <div className='w-full '>
                                <p className='mb-0 text-center text-[#898989]'>Super Bowl</p>
                            </div>
                            <div className='w-full '>
                                <p className='mb-0 text-center text-[#898989]'>AFC</p>
                            </div>
                            <div className='w-full '>
                                <p className='mb-0 text-center text-[#898989]'>AFC</p>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col gap-1'>
                        <ThreeColItems detailTotal1='1.95' detailTotal2='-' detailTotal3='1.80' name='49ers' />
                        <ThreeColItems detailTotal1='1.95' detailTotal2='1.80' detailTotal3='-' name='Ravens' />
                        <ThreeColItems detailTotal1='1.95' detailTotal2='1.80' detailTotal3='-' name='Bills' />
                        <ThreeColItems detailTotal1='1.95' detailTotal2='1.80' detailTotal3='-' name='Chiefs' />
                        <ThreeColItems detailTotal1='1.95' detailTotal2='-' detailTotal3='1.80' name='Lions' />
                        <ThreeColItems detailTotal1='1.95' detailTotal2='-' detailTotal3='1.80' name='Packers' />
                        <ThreeColItems detailTotal1='1.95' detailTotal2='-' detailTotal3='1.80' name='Buccaneers' />
                        <ThreeColItems detailTotal1='1.95' detailTotal2='1.80' detailTotal3='-' name='Texans' />
                    </div>
                </div>,
        }
    ];
    const superBowl: CollapseProps['items'] = [
        {
            key: '1',
            label: 'Super Bowl MVP',
            children:
                <>
                    <div className='flex flex-col gap-2'>
                        {WinningMargin.map((item) => (
                            <div className='flex items-center' key={`winning_${item.id}`}>
                                <div className='w-1/2'>
                                    <p className='text-left font-bold'>{item.name}</p>
                                </div>
                                <div className='w-1/2 border-[1px] border-gray-300 rounded-lg py-2 '>
                                    <p className='font-bold'>{item.total}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
        }
    ];
    const centralDivision: CollapseProps['items'] = [
        {
            key: '1',
            label: 'Central Division Winner',
            children:
                <>
                    <div className='flex flex-col gap-2'>
                        {WinningMargin.map((item) => (
                            <div className='flex items-center' key={`winning_${item.id}`}>
                                <div className='w-1/2'>
                                    <p className='text-left font-bold'>{item.name}</p>
                                </div>
                                <div className='w-1/2 border-[1px] border-gray-300 rounded-lg py-2 '>
                                    <p className='font-bold'>{item.total}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
        }
    ];
    const southweastDivison: CollapseProps['items'] = [
        {
            key: '1',
            label: 'Southweast Division Winner',
            children:
                <>
                    <div className='flex flex-col gap-2'>
                        {WinningMargin.map((item) => (
                            <div className='flex items-center' key={`winning_${item.id}`}>
                                <div className='w-1/2'>
                                    <p className='text-left font-bold'>{item.name}</p>
                                </div>
                                <div className='w-1/2 border-[1px] border-gray-300 rounded-lg py-2 '>
                                    <p className='font-bold'>{item.total}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
        }
    ];
    const winningMargin: CollapseProps['items'] = [
        {
            key: '1',
            label: 'Winning Margin',
            children:
                <>
                    <div className='flex flex-col gap-2'>
                        {WinningMargin.map((item) => (
                            <div className='flex items-center' key={`winning_${item.id}`}>
                                <div className='w-1/2'>
                                    <p className='text-left font-bold'>{item.name}</p>
                                </div>
                                <div className='w-1/2 border-[1px] border-gray-300 rounded-lg py-2 '>
                                    <p className='font-bold'>{item.total}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
        }
    ];
    const totalPoints: CollapseProps['items'] = [
        {
            key: '1',
            label: 'Total points in regulation',
            children:
                <>
                    <div className='flex flex-col gap-2'>
                        {TotalPoints.map((item) => (
                            <div className='flex items-center' key={`winning_${item.id}`}>
                                <div className='w-1/2'>
                                    <p className='text-left font-bold'>{item.name}</p>
                                </div>
                                <div className='w-1/2 border-[1px] border-gray-300 rounded-lg py-2 '>
                                    <p className='font-bold'>{item.total}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
        }
    ];
    return (
        <div className='mt-[22px] detailContent flex flex-col gap-3'>
            <div className='w-full'>
                <Collapse items={outringtWinner} defaultActiveKey={['1']} />
            </div>
            <div className='w-full flex gap-3'>
                <div className='w-1/2'>
                    <Collapse items={superBowl} defaultActiveKey={['1']} />
                    <Collapse items={centralDivision} defaultActiveKey={['1']} />
                    <Collapse items={southweastDivison} defaultActiveKey={['1']} />
                </div>
                <div className='w-1/2'>
                    <Collapse items={winningMargin} defaultActiveKey={['1']} />
                    <Collapse items={totalPoints} defaultActiveKey={['1']} />
                </div>


            </div>
        </div>
    )
}

export default FutureTabs;