/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, useNavigate } from "react-router-dom";
import './App.css';
import axiosClient from './api/axiosClient';
import LoadingGlobal from './components/Loading/Loader';
// import ToastGlobal from './components/ToastGlobal';
import Router from './routes/Router';
import { useEffect, useRef } from "react";
import * as homePageApi from './api/homePage/homePageApi'
import useResetData from "./hook/useResetData";
// import "antd/dist/reset.css"
function App() {
  const location = useLocation();
  const navigate = useNavigate()
  const { resetLoginOut } = useResetData()

  const viewSettings = async () => {
    const ketqua = await homePageApi.viewSettings()
    if (ketqua?.success) {
      var pathname = []
      if (ketqua.data.navigation.SOC === 1) {
        pathname.push('/Soccer_V1')
      }
      if (ketqua.data.navigation.NBA === 1) {
        pathname.push('/NBA')
      }
      if (ketqua.data.navigation.NFL === 1) {
        pathname.push('/NFL')
      }
      if (ketqua.data.navigation.MLB === 1) {
        pathname.push('/MLB')
      }
      if (ketqua.data.navigation.NCAAF === 1) {
        pathname.push('/NCAAF')
      }
      if (pathname.length === 0) {
        resetLoginOut()
        window.location.href = "/otp-confirm"
      } else {
        if (!pathname.includes(location.pathname)) {
          window.location.href = pathname[0]
        }
      }
    }
  }

  useEffect(() => {
    viewSettings()
  }, [location.pathname])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const currentPath = location.pathname;
  axiosClient.interceptors.response.use(
    (response) => response,
    // (response) => response,
    (error) => {
      if ((error.response) && (error.response.status === 403 || error.response.status === 401)) {
        resetLoginOut()
        navigate("/otp-confirm")
        return error?.response?.data;
      }
    }
  );

  const timerRef = useRef<number | undefined>(undefined);
  const resetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      return
    }
    timerRef.current = window.setTimeout(() => {
      // Kích hoạt chế độ sleep
      activateSleepMode();
    }, 1800000); // 1 giờ
  };

  const activateSleepMode = () => {
    // alert('Ứng dụng sẽ vào chế độ ngủ do không có thao tác trong 1 giờ.');
    window.location.reload();
    // Thực hiện các hành động cần thiết để vào chế độ sleep
    // Ví dụ: đóng phiên làm việc, chuyển đến trang login, hoặc tắt các chức năng không cần thiết
  };

  useEffect(() => {
    // Đặt lại timer khi phát hiện có hoạt động của người dùng
    const handleUserActivity = () => {
      resetTimer();
    };

    // Lắng nghe các sự kiện người dùng
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);
    window.addEventListener('scroll', handleUserActivity);
    window.addEventListener('click', handleUserActivity);
    window.addEventListener('touchstart', handleUserActivity);
    window.addEventListener('touchmove', handleUserActivity);
    window.addEventListener('touchend', handleUserActivity);

    // Thiết lập timer lần đầu tiên
    resetTimer();

    // Xóa các sự kiện và timer khi component bị unmount
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
      window.removeEventListener('scroll', handleUserActivity);
      window.removeEventListener('click', handleUserActivity);
      window.removeEventListener('touchstart', handleUserActivity);
      window.removeEventListener('touchmove', handleUserActivity);
      window.removeEventListener('touchend', handleUserActivity);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* <ToastGlobal /> */}
      <LoadingGlobal />
      <Router />
    </>
  );
}

export default App;
